import { KeyValueModel } from './../../../../model/keyValueModel';
import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { FileMappingInfoModel, MapFileColumnModel, MapFileModel } from './state';
import { ContributionModel, FileUploadContributionModel } from 'src/app/model/contribution.model';

export const ResetFormAction = createAction('[Map File Edit View] reset form');

export const RequestAction = createAction('[Map File Edit View] request data by Id', props<{ id: number, employmentId: number }>());

export const ResponseAction = createAction('[Map File Edit View] response data by Id',
  props<{ payload: MapFileModel }>());

export const SubmitRequestAction = createAction('[Map File Edit View] Map File Edit details',
  props<{ data: MapFileModel }>());

export const SubmitResponseAction = createAction('[Map File Edit View]  Employee update completed',
  props<{ data: OkModel<string> }>());


export const ValidateUploadFileRequestAction = createAction('[Map File Edit View] validateUploadFile Request',
  props<{ data: FileMappingInfoModel[] }>());

export const ValidateUploadFileResponseAction = createAction('[Map File Edit View]  validateUploadFile Response',
  props<{ data: FileUploadContributionModel }>());


  export const SubmitUploadFileRequestAction = createAction('[Map File Edit View] submit UploadFile Request',
  props<{ data: FileMappingInfoModel[] }>());

export const SubmitUploadFileResponseAction = createAction('[Map File Edit View]  submit UploadFile Response',
  props<{ data: FileUploadContributionModel }>());


export const NextStepAction = createAction('[Map File Edit View] next');
export const PreviousStepAction = createAction('[Map File Edit View] previous');

export const FileContentChangedAction = createAction('[Map File Edit View] content changed', props<{data: string}>());

export const RequestMapToOptionsAction = createAction('[Map File Edit View] request map to options');

export const ResponseMapToOptionsAction = createAction('[Map File Edit View] response map to options', props<{ data: MapFileColumnModel[] }>());

export const RequestTemplateAction = createAction('[Map File Edit View] request template');

export const ResponseTemplateAction = createAction('[Map File Edit View] response template',
  props<{ data: any }>());
