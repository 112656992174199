import { getLogIn_Form, getLogIn_isUnavailable, getLogIn_isUnavailableMessage } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { CheckAvailabilityRequestAction, LoginAction } from './actions';
import { SystemConfigurationRequestAction } from '../../store/common/common.actions';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(getLogIn_Form));
  isUnavailable$ = this.store.pipe(select(getLogIn_isUnavailable));
  isUnavailableMessage$ = this.store.pipe(select(getLogIn_isUnavailableMessage));
  helper = new Helper();
  constructor(public store: Store<AppState>,
    private recaptchaV3Service: ReCaptchaV3Service,) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, SystemConfigurationRequestAction());
    this.dispatch(this.store, CheckAvailabilityRequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  loginUser(login) {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        var clone = this.helper.clone(login);
        clone.token = token;
        this.store.dispatch(LoginAction({ payload: clone }));
      });
  }
}
