<div id="employer-edit-container">
  <div class="card">
    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="d-flex" *ngIf="isCloneMode$|async">
        <div class="alert alert-info flex-fill mr-1 d-flex  flex-column">
          <div class="alert-icon"
            matTooltip="Employees with an Employment End date more than 6 months from today have been removed from this contribution submission">
            <mat-icon>info_outline</mat-icon>
          </div>
          <h1>
            {{form.value.employeesRemoved}}
          </h1>
          Employees Removed
        </div>

        <div class="alert alert-info flex-fill ml-1 mr-1 d-flex  flex-column">
          <div class="alert-icon"
            matTooltip="Employees who were on the original contribution that have been copied over to this new submission">
            <mat-icon>info_outline</mat-icon>
          </div>
          <h1>
            {{form.value.employeesAdded}}
          </h1>
          Employees Copied to this Contribution
        </div>

        <div class="alert alert-info flex-fill ml-1 d-flex  flex-column">
          <div class="alert-icon"
            matTooltip="Any New Employees added to this Employer since the original contribution was submitted, have been added to this contribution submission">
            <mat-icon>info_outline</mat-icon>
          </div>
          <h1>
            {{form.value.employeesNew}}
          </h1>
          New Employees
        </div>

      </div>

      <div class="d-flex flex-wrap">
        <div class="w-100">
          <app-entry-date-month-year title="Contribution Month" [date]="form.controls.startDate" icon="contact_page"
            class="w-50 pr-1">
          </app-entry-date-month-year>
        </div>
        <!-- <app-entry-date title="Contribution End Date" [date]="form.controls.endDate" icon="contact_page"
          class="w-50 pr-1">
        </app-entry-date> -->

        <app-readonly title="Severance Total" [value]="form.value.severanceTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Jetco Total" [value]="form.value.trainingTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Donation Total" [value]="form.value.donationTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Contribution Total" [value]="form.value.contributionTotal" icon="attach_money"
          [tooltip]="form.value.contributionTotal == 0?'Contribution Total must be greater than 0':''"
          class="w-50 pl-1">
        </app-readonly>
        <div class="table-wrapper  w-100 d-flex flex-column">
          <app-button icon="add" class="d-flex align-self-end mt-4 mb-2" color="light" title="Add Employee"
            *ngIf="isEditMode$|async" routerLink="/employee-add">
          </app-button>
          <div class="contribution">
            <div class="loading-page d-flex flex-fill align-items-center" *ngIf="isLoading$ | async">
              <mat-spinner style="margin: auto;" [diameter]="40"></mat-spinner>
            </div>

            <div class="display-employees" *ngIf="isEditMode$|async">
              <app-button-toggle title="Display only active employees" class="w-100 pr-1 mb-3" [customList]="yesNo$"
                [control]="form.controls.displayValid"></app-button-toggle>
            </div>
            <div class="tableFixHead">
              <div class="d-flex flex-row" *ngIf="(columnSort$ | async); let columnSort">
                <div  class=" col" scope="col" (click)="onColumnHeaderClick('employeeCode')">
                  <div class="col-value flex-row">
                    Employee Code
                    <div *ngIf="columnSort.column === 'employeeCode'">
                      <mat-icon class="mr-1 icon-sort" *ngIf="columnSort.order === 'ASC'">keyboard_arrow_up
                      </mat-icon>
                      <mat-icon class="mr-1 icon-sort" *ngIf="columnSort.order === 'DESC'">keyboard_arrow_down
                      </mat-icon>
                    </div>
                  </div>
                </div>
                <div class=" col" scope="col" (click)="onColumnHeaderClick('reference')">
                  <div class="col-value flex-row">
                    Reference
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'reference' && columnSort.order === 'ASC'">
                      keyboard_arrow_up</mat-icon>
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'reference' && columnSort.order === 'DESC'">
                      keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div class=" col" scope="col" (click)="onColumnHeaderClick('fullName')">
                  <div class="col-value flex-row">
                    Full Name
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'fullName' && columnSort.order === 'ASC'">
                      keyboard_arrow_up</mat-icon>
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'fullName' && columnSort.order === 'DESC'">
                      keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div class=" col" scope="col" (click)="onColumnHeaderClick('dateOfBirth')">
                  <div class="col-value flex-row">
                    Date of Birth
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'dateOfBirth' && columnSort.order === 'ASC'">
                      keyboard_arrow_up</mat-icon>
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'dateOfBirth' && columnSort.order === 'DESC'">
                      keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <!-- <th scope="col">Status</th> -->
                <div class=" col" scope="col" (click)="onColumnHeaderClick('startDate')">
                  <div class="col-value flex-row">
                    Employment Start Date
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'startDate' && columnSort.order === 'ASC'">
                      keyboard_arrow_up</mat-icon>
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'startDate' && columnSort.order === 'DESC'">
                      keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div class=" col" scope="col" (click)="onColumnHeaderClick('endDate')">
                  <div class="col-value flex-row">
                    Employment End Date
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'endDate' && columnSort.order === 'ASC'">
                      keyboard_arrow_up</mat-icon>
                    <mat-icon class="mr-1 icon-sort"
                      *ngIf="columnSort.column === 'endDate' && columnSort.order === 'DESC'">
                      keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div class=" col" scope="col">Weeks
                  <app-tooltip [text]="'employerPortal_Contribution_Weeks'|message|async"></app-tooltip>

                </div>
                <div class=" col" scope="col">Severance Amount
                  <app-tooltip [text]="'employerPortal_Contribution_Severance'|message|async"></app-tooltip>

                </div>
                <div class=" col" scope="col">Jetco Amount
                  <app-tooltip [text]="'employerPortal_Contribution_Training'|message|async"></app-tooltip>

                </div>
                <div class=" col" scope="col">Donation Amount
                  <app-tooltip [text]="'employerPortal_Contribution_Donation'|message|async"></app-tooltip>

                </div>
                <div class=" col" scope="col">Total</div>
                <div class=" col" scope="col">Final Contribution
                  <app-tooltip [text]="'employerPortal_Contribution_FinalContribution'|message|async">
                  </app-tooltip>

                </div>
              </div>
              <cdk-virtual-scroll-viewport itemSize="50" style="overflow: auto;" style="height: 600px" itemSize="{{rowHeight}}">

                <table class="table table-hover {{(isEditMode$|async)== true?'edit':''}}">

                  <tbody>
                    <tr style="height: {{rowHeight}}px;" *cdkVirtualFor="let item of contributionList;let i = index;trackBy: trackByFn" class="click d-flex "
                      [ngClass]="{'highlight': item.value.isNewMember && isUploadFile}">
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{item.value.employeeCode}}
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{item.value.reference}}
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}} ">
                        {{item.value.fullName}}
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{item.value.dateOfBirth | date:'dd/MM/yyyy'}}
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{item.value.startDate | date:'dd/MM/yyyy'}}
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{item.value.endDate | date:'dd/MM/yyyy'}}
                      </td>
                      <td class="">
                        <app-entry-number [showincremental]="false" title="" *ngIf="isEditMode$|async" [decimalplaces]="0"
                          [control]="item.controls.weeks" class=" ">
                        </app-entry-number>
                        <div *ngIf="!(isEditMode$|async)">{{item.value.weeks}}</div>
                      </td>
                      <td class="">
                        <app-entry-number [showincremental]="false" title="" *ngIf="isEditMode$|async" [decimalplaces]="2"
                          [control]="item.controls.severanceAmount" class="">
                        </app-entry-number>
                        <div *ngIf="!(isEditMode$|async)">{{item.value.severanceAmount}}</div>
                      </td>
                      <td class="">
                        <app-entry-number [showincremental]="false" title="" *ngIf="isEditMode$|async" [decimalplaces]="2"
                          [control]="item.controls.trainingAmount" class=" ">
                        </app-entry-number>
                        <div *ngIf="!(isEditMode$|async)">{{item.value.trainingAmount}}</div>
                      </td>
                      <td class="">
                        <app-entry-number [showincremental]="false" title="" *ngIf="isEditMode$|async" [decimalplaces]="2"
                          [control]="item.controls.donationAmount" class="">
                        </app-entry-number>
                        <div *ngIf="!(isEditMode$|async)">{{item.value.donationAmount}}</div>
                      </td>
                      <td class=" {{(isEditMode$|async)== true?'pt-4':''}}">
                        {{calculateContributionTotal(item.value) | currency}}
                      </td>
                      <td class=" final-contribution {{(isEditMode$|async)== true?'pt-3':''}}">
                        <app-button-toggle title="" class="w-100" [customList]="yesNo$" *ngIf="isEditMode$|async"
                          [control]="item.controls.finalContribution">
                        </app-button-toggle>
                        <div *ngIf="!(isEditMode$|async)">{{item.value.finalContribution === false ? 'N' : 'Y'}}</div>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>

            </div>

          </div>
        </div>
      </div>
      <div *ngIf="form.value.isClone" class="alert {{form.value.youAcknowledge?'alert-success':'alert-danger'}}  mt-4">
        <app-checkbox title="This contribution submission is a copy of a previous submission, please check that the Number of Weeks, Severance Amount, JETCO Amount and Donation Amount for each Employee are correct for the current submission.
        Please check this box to confirm the amounts are correct and you wish to submit."
          [control]="form.controls.youAcknowledge">
        </app-checkbox>
      </div>
      <div class="components-actions mt-4">
        <app-button loading class="float-right w-25" title="Submit Details" icon="save" type="submit"
          *ngIf="isEditMode$|async" [isDisabled]="form?.isInvalid" [form]="form" [displayDialog]="true" 
          dialogText="Please confirm you wish to submit the contributions to continue." (onClick)="onSubmitClick($event, form.value)">
        </app-button>
        <app-button loading class="float-right w-25 mr-2" color="light" title="Save Details" icon="save" type="submit"
          [form]="form" *ngIf="isEditMode$|async" [isDisabled]="form?.isInvalid" (onClick)="onSaveClick(form.value)">
        </app-button>
      </div>

    </form>
  </div>

  <ng-template #myTemplate>
    <div class="p-4">
      <h3 class="mb-3">Thank you for submitting the contribution for the period of {{(submitResponse$ |
        async)?.startDate | date:'dd/MM/yyyy'}} to {{(submitResponse$ | async)?.endDate | date:'dd/MM/yyyy'}}
      </h3>
      <p>
        Please deposit the amount of {{(submitResponse$ | async)?.amount | currency}} into the CIRT Bank Account
      </p>
      <p>
        Account Name: {{(submitResponse$ | async)?.bankDetails.accountName}}</p>
      <p>BSB: {{(submitResponse$ | async)?.bankDetails.bsb}}</p>
      <p>Account Number: {{(submitResponse$ | async)?.bankDetails.accountNumber}}</p>
      <p>
        Please use the reference {{(submitResponse$ | async)?.bankDetails.reference}}
      </p>
      <p>
        If any Employees are exiting as a part of this contribution, please ensure you complete a Certificate of
        Employment at your earliest convenience
      </p>
      <app-button loading class="float-right w-25 mb-4 mt-4" title="Return to Dashboard" icon="keyboard_return"
        type="submit" (onClick)="onGoToDashboardClick()">
      </app-button>
    </div>
  </ng-template>

</div>
