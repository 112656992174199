import * as moment from "moment";
import { Boxed, ValidationErrors, unbox } from "ngrx-forms";

export interface DateGreaterThanOrEqualToValidationError {
  comparand: string;
  actual: string
}

// @ts-ignore
declare module 'ngrx-forms' {
  export interface ValidationErrors {
    dateGreaterThanOrEqualTo?: DateGreaterThanOrEqualToValidationError;
  }
}

/**
 * A validation function that requires the end date to be greater than or equal to a starting date.
 * The validation error returned by this validation function has the following shape:
 *
```typescript
{
  dateGreaterThanOrEqualTo: {
    comparand: string;
    actual: string;
  };
}
```
 * Use this validation function in conjunction with the `validate`
 * update function to perform synchronous validation in a reducer:
 *
```typescript
updateGroup<MyFormValue>({
  endDate: validate(dateGreaterThanOrEqualTo('30/12/2021')),
})
```
*/
export function dateGreaterThanOrEqualTo(comparand: string) {
  return <T extends string | Boxed<string> | null | undefined>(value: T): ValidationErrors => {
    value = unbox(value) as string | null | undefined as T;

    if (value === null || value === undefined || (value as string).length === 0) {
      return {};
    }

    const endDate = moment(value as string, "YYYY-MM-DD");
    const startDate = moment(comparand, "YYYY-MM-DD");

    if (endDate.isSameOrAfter(startDate)) {
      return {};
    }

    return {
      dateGreaterThanOrEqualTo: {
        comparand,
        actual: value as string
      },
    };
  };
}