import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EPEmployeeService } from 'src/app/services/EmployerPortalServices/ep-employee.service';
import { EPContributionService } from 'src/app/services/EmployerPortalServices/ep-contribution.service';

@Injectable()
export class AddEmployeeEffects {
  constructor(private actions$: Actions,
    public toastr: ToastrService,
    private service: EPEmployeeService,
    private contributionService: EPContributionService) {
  }

  getById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getEmployee(action.id, 0)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  getContributionRate$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestContributionRateAction),
    switchMap(() => this.contributionService.getRates()),
    map((data: any) => {
      return ActionsList.ResponseContributionRateAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.service.addEmployeeDetails(action.payload)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));
}
