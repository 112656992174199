import { IMainLayoutState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { Helper } from '@ifaa-components/ui-components';
import { MainLayoutAnimatedAction, ResponseEmployerInfoAction } from './actions';
import { EmployerModel } from 'src/app/model/employer.model';

export const mainLayoutState: IMainLayoutState = {
  page: 0,
  animated: false,
  employer: new EmployerModel()
};
var helper = new Helper();

const reducer = createReducer(mainLayoutState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(MainLayoutAnimatedAction, (state) => {
    return {
      ...state,
      animated: true
    };
  }),
  on(ResponseEmployerInfoAction, (state, { payload }) => {
    return {
      ...state,
      employer: payload
    };
  })

);

export function mainLayoutReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
