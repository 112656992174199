import { AppState } from '../../../../store/app.states';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { LoadMoreAction, RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { newEmployeeListView_Filter, newEmployeeListView_List } from './selectors';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-new-employee-list-view',
  templateUrl: './new-employee-list-view.component.html',
  styleUrls: ['./new-employee-list-view.component.scss']
})
export class NewEmployeeRequestListViewComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(newEmployeeListView_List));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  filter$ = this.appstore.pipe(select(newEmployeeListView_Filter));
  @Input() fullpage: boolean = true;

  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.filter$
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => prev.value.q === curr.value.q && prev.value.currentPage === curr.value.currentPage))
      .subscribe(filter => {
        if (filter.value.currentPage != -1)
          this.store.dispatch(RequestAction({ search: filter.value.q, page: filter.value.currentPage }));
      })
    this.screenWidthChange(this.appstore, x => { });
  }

  endOfPageEvent() {
    if (!this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  endOfPageFullEvent() {
    if (this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  personalDetails() {
    this.router.navigate(['personal-details']);
  }

  changePassword() {
    this.router.navigate(['change-password']);
  }

  // onSelectEmployeeClick(model: EmployeeModel) {
  //   this.router.navigate(['change-password']);
  // }
}
