<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="work">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Employer Details</div>
      </div>
      <div class="d-flex flex-column" slot="end">
        <!-- <app-button class=" float-right" title="Edit Employer" icon="edit"
        (onClick)="onEmployerEditClick()">
      </app-button> -->
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-employer-view></app-employer-view>
      </div>
      <div class="m-3 components-actions d-flex flex-row-reverse" slot="actions">

        <app-button class=" float-right w-25" title="Edit Employer" icon="edit"
          (onClick)="onEmployerEditClick()">
        </app-button>

      </div>
      <!-- <div class="m-3 components-actions" slot="actions">
        <app-button class=" float-right w-25" title="Edit Employer" icon="edit"
          (onClick)="onEmployerEditClick()">
        </app-button>
      </div> -->
    </app-component-wrapper>
  </div>
</div>
