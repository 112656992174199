import { IsLoggedInRequestAction } from './../../store/common/common.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { WebUserService } from 'src/app/services/webuser.service';

@Injectable()
export class LoginEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private service: WebUserService,
    private webUserService: WebUserService,
  ) {
  }

  login$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.LoginAction),
    switchMap((action: any) => this.service.authenticate(action.payload)),
    map((data: any) => {
      window.localStorage.setItem('token_name', data.data);
      this.router.navigate(['dashboard']);
      this.store.dispatch(IsLoggedInRequestAction());
      return ActionsList.LoggedInAction({ payload: data.data });
    })
  ));

  checkAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CheckAvailabilityRequestAction),
    switchMap((action: any) => this.webUserService.isMaintenanceMode()),
    map((data: any) => {
      return ActionsList.CheckAvailabilityResponseAction({ payload: data });
    })
  ));

}
