import { EmployerModel } from 'src/app/model/employer.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class EPEmployerService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getEmployer(): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/Employer`, { headers: this.headers() });
  }

  getPreviousContributions(page: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/employer-portal/Employer/previous-contributions`, { headers: this.headers(), params: params });
  }

  getDraftContributions(page: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/employer-portal/Employer/draft-contributions`, { headers: this.headers(), params: params });
  }

  getProcessedContributions(page: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/employer-portal/Employer/processed-contributions`, { headers: this.headers(), params: params });
  }

  getEmployeeList(search: string, page: number): Observable<any> {
    const params = new HttpParams()
      .set('search', search)
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/employer-portal/Employer/employee-list`, { headers: this.headers(), params: params });
  }

  getNewEmployeeList(search: string, page: number): Observable<any> {
    const params = new HttpParams()
      .set('search', search)
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/employer-portal/Employer/new-employees`, { headers: this.headers(), params: params });
  }

  updateEmployerDetails(model: EmployerModel): Observable<any> {
    return this.http.put(environment.apiUrl + `/employer-portal/Employer`, model, { headers: this.headers() });
  }

}
