import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { ICertificateOfEmploymentViewState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const viewCertificateOfEmployment = createSelector(state, (state) => state.certificateOfEmploymentViewState);

export const viewCertificateOfEmployment_Data = createSelector(
  viewCertificateOfEmployment,
  (state: ICertificateOfEmploymentViewState) => state.data
);

export const viewCertificateOfEmployment_Employee = createSelector(
  viewCertificateOfEmployment,
  (state: ICertificateOfEmploymentViewState) => state.employee
);
