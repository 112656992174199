import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import {
  RequestAction, ResetFormAction, SubmitRequestAction, PreviousStepAction, NextStepAction,
  FileContentChangedAction, RequestMapToOptionsAction, ValidateUploadFileRequestAction, RequestTemplateAction, SubmitUploadFileRequestAction
} from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import {
  mapFileState_Columns, mapFileState_Contribution, mapFileState_contributionCheck, mapFileState_CurrentStep, mapFileState_Form, mapFileState_LastModified,
  mapFileState_mapToOptions, mapFileState_mapToOptionsKV, mapFileState_totalColumn, mapFileState_TotalSteps
} from './selectors';
import { FileMappingInfoModel, MapFileModel } from './state';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoadUploadFileAction } from '../contribution-edit/actions';
import { Helper } from '@ifaa-components/ui-components';
import { JoyrideService } from 'ngx-joyride';
import { AppState } from 'src/app/store/app.states';


@Component({
  selector: 'app-map-file-edit',
  templateUrl: './map-file-edit.component.html',
  styleUrls: ['./map-file-edit.component.scss']
})
export class MapFileEditComponent extends ComponentBase implements OnInit, OnDestroy {
  helper = new Helper();
  form$ = this.store.pipe(select(mapFileState_Form));
  lastModified$ = this.store.pipe(select(mapFileState_LastModified));
  currentStep$ = this.store.pipe(select(mapFileState_CurrentStep));
  totalSteps$ = this.store.pipe(select(mapFileState_TotalSteps));
  mapToOptions$ = this.store.pipe(select(mapFileState_mapToOptions));
  mapToOptionsKV$ = this.store.pipe(select(mapFileState_mapToOptionsKV));
  contributionCheck$ = this.store.pipe(select(mapFileState_contributionCheck));
  totalColumn$ = this.store.pipe(select(mapFileState_totalColumn));
  columns$ = this.store.pipe(select(mapFileState_Columns));
  contribution$ = this.store.pipe(select(mapFileState_Contribution));
  mapToSelected: any;
  mapToSelectIndex = 0;
  extensions: string[] = ['.csv'];
  maxfilesize = 5242880;//5mb
  totalcells = 0;
  mapToAnimation = '';
  selectedColumn = 'A';
  tenantConfig$ = this.appStore.pipe(select(commomState_SystemConfig_Config));
  requiredColumnKeys = [];
  constructor(public store: Store<EmployerPortalSharedState>,
    public activatedRoute: ActivatedRoute,
    private readonly joyrideService: JoyrideService,
    public appStore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, ResetFormAction());

    this.store.dispatch(RequestMapToOptionsAction());
    this.currentStep$.subscribe(x => {
      if (x == 1) {
        setTimeout(() => {
          if (!localStorage.getItem('MapFileJoyRide')) {
            localStorage.setItem('MapFileJoyRide', 'true');
            this.startJoyRide();
          }
        }, 1000);

      }
    })
    this.sub = this.contributionCheck$.subscribe(x => {
      if (x && x.errorList.length == 0) {
        this.store.dispatch(LoadUploadFileAction({ payload: x }));
      }
    });

    this.sub = this.form$.subscribe(x => {
      if (x && x.value?.mapping.length > 0 && !this.mapToSelected) {
        this.mapToSelected = x.controls.mapping.controls[0].controls;
      }
    });

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['employee-details']);
        this.dispatch(this.store, ResetFormAction());
      }
    });

    this.getRouteParam(this.activatedRoute, 'id', (id) => {
      // this.dispatch(this.store, RequestAction({ id }));
    });

    this.sub = combineLatest([this.mapToOptions$, this.form$])
      .pipe(
        map(([mapOptions, form]) => ({ mapOptions, form })),
      ).subscribe(x => {
        if (x.mapOptions && x.form) {
          this.requiredColumnKeys = [];
          x.mapOptions.map(map => {
            if (map.isRequired) {
              var found = x.form.value.mapping.filter(a => a.mapTo == map.key);
              if (found.length == 0) {
                this.requiredColumnKeys.push(map.key);
              }
            }
          });
        }
      });
  }

  reviewScreenHasError(errorList: any[]) {
    var hasError = false;
    if (errorList) {
      errorList.forEach(element => {
        if (element.isError)
          hasError = true;
      });
    }
    return hasError;
  }

  warningCount(errorList: any[]) {
    var count = 0;
    if (errorList) {
      errorList.forEach(element => {
        if (!element.isError)
          count++;
      });
    }
    return count;
  }

  errorCount(errorList: any[]) {
    var count = 0;
    if (errorList) {
      errorList.forEach(element => {
        if (element.isError)
          count++;
      });
    }
    return count;
  }


  startJoyRide() {
    this.joyrideService.startTour(
      { steps: ['firstStep', 'thirdStep', 'fourthStep'] } // Your steps order
      // { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep'] } // Your steps order
    );
  }
  onHelpClick() {
    this.startJoyRide();
  }
  onFileContentChange(event: any) {
    var tt = event;
    this.store.dispatch(FileContentChangedAction({ data: event }))

  }
  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  trackByFn(index, item) {
    return index;
  }

  onSubmitClick(data: MapFileModel) {
    this.dispatch(this.store, SubmitRequestAction({ data: data }));

  }

  onPreviousClick() {
    this.dispatch(this.store, PreviousStepAction());
  }

  onGetTemplateClick() {
    this.dispatch(this.store, RequestTemplateAction());
  }

  onNextClick() {
    this.dispatch(this.store, NextStepAction());
  }

  onValidateRequestClick(data: FileMappingInfoModel[]) {
    this.dispatch(this.store, ValidateUploadFileRequestAction({ data }));
  }

  onSubmitRequestClick(data: FileMappingInfoModel[]) {
    this.dispatch(this.store, SubmitUploadFileRequestAction({ data }));
  }

  lastFileMappingInfoModel = '';
  getFileData(data: FileMappingInfoModel[]) {
    // var f = JSON.stringify(data);
    // if (f == this.lastFileMappingInfoModel) {
    //   return JSON.parse(this.lastFileMappingInfoModel);
    // }
    // this.lastFileMappingInfoModel = f;
    if (data.length == 0) return [];
    var content = [];

    content = content.concat(this.formatContent(0, data));
    content = content.concat(this.formatContent(1, data));
    content = content.concat(this.formatContent(2, data));
    content = content.concat(this.formatContent(3, data));

    return content;
  }

  formatContent(r: number, data: FileMappingInfoModel[]) {
    var content = [];

    for (let row = 0; row < data.length; row++) {


      if (!data[row].columnName) {
        //content.push('');
        content.push({ key: '', value: '' });
      }
      else {
        //content.push(data[row].columnContent[r]);
        content.push({ key: data[row].columnName, value: data[row].columnContent[r] });
      }
    }

    return content;
  }


  async mapToContent(val: any) {
    var form = await this.helper.getValue(this.form$);
    var d = form.value.mapping.filter(x => x.columnName == val.key);
    var idx = form.value.mapping.indexOf(d[0]);

    this.mapTo(idx, form.controls.mapping.controls[idx].controls)
  }
  mapTo(i: number, control: any) {

    if (this.mapToSelected) {
      this.mapToAnimation = 'animate__fadeOutUp';
      setTimeout(async () => {
        var columns = await this.helper.getValue(this.columns$);
        this.mapToAnimation = 'animate__fadeInDown';
        this.mapToSelectIndex = i;
        this.mapToSelected = control;
        this.selectedColumn = columns[i];
        var element = document.getElementById('alphabet-' + i);//
        this.checkViewIsVisible(element);
      }, 0);
    }
    else {
      this.mapToSelected = control;
    }
  }
  checkViewIsVisible(element) {
    var main = document.getElementsByClassName('cells')[0];
    if ((element.offsetLeft + element.clientWidth) - main.scrollLeft >= main.clientWidth) {
      var left = (element.offsetLeft + element.clientWidth) - main.clientWidth;
      main.scroll(
        {
          left: left,
        });
    }
    if (element.offsetLeft < main.scrollLeft) {
      left = element.offsetLeft - main.clientLeft;

      main.scroll(
        {
          left: left,
        });
    }

  }
  onNextColumnClick(data: any) {
    var columnsList = data.value.filter(x => x.columnName);
    if (this.mapToSelectIndex < columnsList.length - 1) {
      this.mapToSelectIndex++;
    } else {
      this.mapToSelectIndex = 0;
    }
    this.mapTo(this.mapToSelectIndex, data.controls[this.mapToSelectIndex].controls);

  }

  onPreviousColumnClick(data: any) {
    var columnsList = data.value.filter(x => x.columnName);
    if (this.mapToSelectIndex == 0) {
      this.mapToSelectIndex = columnsList.length - 1;
    } else {
      this.mapToSelectIndex--;
    }
    this.mapTo(this.mapToSelectIndex, data.controls[this.mapToSelectIndex].controls);

  }

}
