import { ContributionModel, FileUploadContributionModel } from 'src/app/model/contribution.model';
import { KeyValueModel } from './../../../../model/keyValueModel';
import { AppFileModel } from '@ifaa-components/ui-components';
import { FormGroupState } from 'ngrx-forms';

export interface IMapFileEditState {
  form: FormGroupState<MapFileModel>;
  currentStep: number;
  totalSteps: number;
  validated: boolean;
  lastModifiedTime: Date;
  mapToOptions: MapFileColumnModel[];
  contributionCheck: FileUploadContributionModel;
  totalColumn: number;
  columns: string[];
  contribution: ContributionModel;
}

export class MapFileModel {
  file: AppFileModel = new AppFileModel();
  mapping: FileMappingInfoModel[] = [];
}

export class MapFileColumnModel {
  key: string = '';
  value: string = '';
  isRequired: boolean = false;
}


export class FileMappingInfoModel {
  columnName: string = '';
  columnContent: string[] = [''];
  mapTo: string = '';
  skip: boolean = false;
  columnIndex: number = 0;


}

