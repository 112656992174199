import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { Title } from "@angular/platform-browser";
import { Meta } from '@angular/platform-browser';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { AppState } from 'src/app/store/app.states';
@Component({
  selector: 'login-master',
  templateUrl: './not-loggedin.master.html',
  styleUrls: ['./not-loggedin.master.scss']
})
export class NotLoggedInMasterComponent extends ComponentBase {

  constructor(public store: Store<AppState>,
    private titleService: Title,
    public dialog: MatDialog,
    private meta: Meta,
    private router: Router) {
    super();
    this.titleService.setTitle("smart Moday – Employer Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(LogoutRequestAction());

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
