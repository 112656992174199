
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { LoadMoreAction, ResetPageAction, ResponseAction } from './actions';
import { IDraftContributionViewState } from './state';

export const initialState: IDraftContributionViewState = {
  list: [],
  currentPage: 0
};

const reducer = createReducer(initialState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: state.currentPage === 0 ? payload : state.list.concat(payload),
      currentPage: payload.length == 0 ? -1 : state.currentPage
    }

  }),
  on(ResetPageAction, (state, { }) => {
    return {
      ...state,
      list: [],
      currentPage: 0
    }

  }),
  on(LoadMoreAction, (state) => {
    return {
      ...state,
      currentPage: state.currentPage + 1
    };
  })
);

export function draftContributionsViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
