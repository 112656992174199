import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISubmittedContributionViewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const selector = createSelector(state, (state) => state.submittedContributionsViewState);

export const previousContributionsView_List = createSelector(
  selector,
  (state: ISubmittedContributionViewState) => state.list
);

export const previousContributionsView_CurrentPage = createSelector(
  selector,
  (state: ISubmittedContributionViewState) => state.currentPage
);
