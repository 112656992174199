import { environment } from 'src/environments/environment';
import { IsLoadingAction, LogoutRequestAction } from './../store/common/common.actions';
import { Injectable, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, of, Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from '../../../node_modules/rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { finalize } from "rxjs/internal/operators/finalize";

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  isLoading = false;
  constructor(private store: Store<any>,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  showError(errors) {
    const toastr = this.toastr;
    errors.forEach(function (element) {
      toastr.error(element);
    });
  }

  removeRequest(req: HttpRequest<any>) {
    this.requests = this.requests.filter(x => x.url != req.url);
    // setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: this.requests.length > 0 })); }, 0)
    var hasValue = this.requests.length > 0;
    if (this.isLoading != hasValue) {
      this.isLoading = hasValue;
      this.store.dispatch(IsLoadingAction({ payload: this.isLoading }));
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authReq = request.clone({
      headers: request.headers
        .set('Content-Security-Policy', "default-src 'self'; style-src 'self' 'unsafe-inline'")
        .set('strict-transport-security', 'max-age=31536000; includeSubdomains; preload'),

    });

    if (!authReq.url.endsWith('.json') && authReq.url.startsWith(environment.apiUrl)) {
      var exist = this.requests.filter(x => {
        return x.url == authReq.url
      });
      if (exist.length == 0) {
        this.requests.push(authReq);
        //setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)
        if (!this.isLoading) {
          this.isLoading = true;
          this.store.dispatch(IsLoadingAction({ payload: true }));
        }
      }
    }

    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
      }),

      finalize(() => {
        this.removeRequest(request);
      }),
      catchError((error, caught) => {
        this.removeRequest(request);

        if (error.status === 401) {
          window.localStorage.setItem('token_name', '');
          if (error.error && error.error.message) {
            this.showError([error.error.message]);
          }
          if (
            !this.router.url.toLowerCase().startsWith('/reset-password')
            && !this.router.url.toLowerCase().startsWith('/forgot-password')) {
            this.router.navigate(['/login']);
          }
        } else {
          if (error.error && error.error.message) {
            this.showError([error.error.message]);
          }
        }
        return of(error);
      }) as any);
  }
}
