import { Component, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { EmployerPortalSharedState } from '../../store/shared.states';

@Component({
  selector: 'app-tooltip',
  templateUrl: './app-tooltip.component.html',
  styleUrls: ['./app-tooltip.component.scss'],
  providers: []
})
export class AppTooltipComponent implements AfterViewInit {

  @Input() text: string = '';
  isOpen = false;
  constructor(public store: Store<EmployerPortalSharedState>,
    private route: ActivatedRoute) {


  }

  ngAfterViewInit(): void {

  }

}
