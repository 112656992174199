import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { EmployerModel } from 'src/app/model/employer.model';

export const ResetFormAction = createAction('[Edit Employer View] reset form');

export const RequestAction = createAction('[Edit Employer View] request data by Id');

export const ResponseAction = createAction('[Edit Employer View] response data by Id',
  props<{ payload: EmployerModel }>());

export const SubmitRequestAction = createAction('[Edit Employer View] save Employer drtails',
  props<{ payload: EmployerModel }>());

export const SubmitResponseAction = createAction('[Edit Employer View]  Employer update completed',
  props<{ payload: OkModel<string> }>());
