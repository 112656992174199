
import { IForgotPasswordState } from './state';
import { ForgotPasswordModel } from '../../model/forgot-password.model';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { SubmitResponseAction, SubmitRequestAction } from './actions';

export const forgotPasswordFormName = 'forgotPasswordForm';

export const forgotPasswordState: IForgotPasswordState = {
  form: createFormGroupState(forgotPasswordFormName, new ForgotPasswordModel())
};

export const validateForgotPasswordForm = updateGroup<ForgotPasswordModel>({
  // email: validate(required, email),
});

const reducer = createReducer(forgotPasswordState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),

  on(SubmitResponseAction, (state) => {
    return {
      ...state,
    };
  }),
);

const forgotPasswordReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForgotPasswordForm,
);

export function forgotPasswordReducer(state: any | undefined, action: Action) {
  return forgotPasswordReducerFormState(state, action);
}
