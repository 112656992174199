import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IContributionEditState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const editContribution = createSelector(state, (state) => state.contributionEditState);

export const editContribution_Form = createSelector(
  editContribution,
  (state: IContributionEditState) => state.form
);

export const editContribution_LastModified = createSelector(
  editContribution,
  (state: IContributionEditState) => state.lastModifiedTime
);

export const editContribution_IsEditMode = createSelector(
  editContribution,
  (state: IContributionEditState) => state.isEditMode
);

export const editContribution_IsCloneMode = createSelector(
  editContribution,
  (state: IContributionEditState) => state.form.value.isClone
);

export const editContribution_CanClone = createSelector(
  editContribution,
  (state: IContributionEditState) => state.form.value.status > 2 && !state.form.value.isClone
);

export const editContribution_SubmitResponse = createSelector(
  editContribution,
  (state: IContributionEditState) => state.response
);

export const editContribution_TotalUpdate = createSelector(
  editContribution,
  (state: IContributionEditState) => state.totalUpdate
);

export const editContribution_ColumnSort = createSelector(
  editContribution,
  (state: IContributionEditState) => state.columnSort
);

