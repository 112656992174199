import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ContributionModel } from 'src/app/model/contribution.model';

export const ResetFormAction = createAction('[View Processed Contribution View] reset form');

export const RequestAction = createAction('[View Processed Contribution View] request data by Id', props<{ id: number }>());

export const ResponseAction = createAction('[View Processed Contribution View] response data by Id',
  props<{ payload: ContributionModel }>());
