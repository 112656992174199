import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { IForgotPasswordState } from './state';

export const forgotPassword = (state: AppState) => state.forgotPasswordState;

export const forgotPassword_Form = createSelector(
  forgotPassword,
  (state: IForgotPasswordState) => state.form
);
