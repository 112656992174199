import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployeeViewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const getEmployeeView = createSelector(state, (state) => state.employeeViewState);

export const getEmployeeView_Model = createSelector(
  getEmployeeView,
  (state: IEmployeeViewState) => state.model
);
