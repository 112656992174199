import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { Helper } from '@ifaa-components/ui-components';
import { ActivatedRoute } from '@angular/router';
import { getNewEmployeeView_Model } from './selectors';

@Component({
  selector: 'app-new-employee-view',
  templateUrl: './new-employee-view.component.html',
  styleUrls: ['./new-employee-view.component.scss']
})
export class NewEmployeeViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(getNewEmployeeView_Model));
  @Input() itemclass: string = 'w-50';
  helper = new Helper();
  constructor(public store: Store<EmployerPortalSharedState>,
    public activatedRoute: ActivatedRoute,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.getRouteParam(this.activatedRoute, 'id', (id) => {
      this.dispatch(this.store, RequestAction({ id }));

    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}