<div id="certificate-of-employment-edit-container">
  <div class="card">
    <div *ngIf="data$ | async; let data">
      <div class="d-flex flex-wrap">

        <app-readonly title="Employee Number" [value]="data.employeeCode" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Employee Name" [value]="data.employeeFullName" class="w-50 p-1">
        </app-readonly>

        <app-readonly title="Date of Birth" [value]="data.dateOfBirth | date:'dd/MM/yyyy' "
          class="w-50 p-1 ">
        </app-readonly>

        <h3 class="w-100 mt-3">Employment Detail</h3>
        <app-readonly title="Employer Name" [value]="data.employerName" class="w-100 pr-1" icon="contact_page">
        </app-readonly>


        <app-readonly title="Start Date" [value]="data.employmentStartDate  | date:'dd/MM/yyyy'" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="End Date" [value]="data.employmentEndDate  | date:'dd/MM/yyyy'" class="w-50 pr-1">
        </app-readonly>

        <div class="alert alert-info">
          <p>I certify that:</p>
          <p>The Employer no longer requires the work which the Employee has been performing to be done by anyone and
            this has led to the termination AND/OR the Employee's employment was terminated due to lack or work; AND the
            termination was not by the Employee by voluntary resignation, retirement, death or permanent disability or
            abandonment, or by the Employer for disciplinary reasons</p>

          <div class="badge badge-secondary">{{data.agreeToCertify? 'Yes': 'No'}}</div>
        </div>
        <app-readonly title="If No, please provide details" [value]="data.certifyNoDetails " class="w-100 pr-1">
        </app-readonly>

        <div *ngIf="data.agreeToCertify">
          <app-readonly title="Employee's Annual Salary" [value]="data.employeeAnnualSalary" class="w-100 pr-1"
            icon="attach_money">
          </app-readonly>

          <app-readonly title="Unpaid absence in weeks" [value]="data.unpaidAbsenceInWeeks " class="w-100 pr-1"
            icon="tag">
          </app-readonly>

          <app-readonly title="Number of weeks for redundancy calculation"
            [value]="data.numberOfWeeksRedundancyCalculation " class="w-100 pr-1" icon="tag">
          </app-readonly>

          <app-readonly title="Total redundancy payment due to employee # times weekly salary rate" icon="attach_money"
            [value]="data.totalRedundancyPayment " class="w-100 pr-1">
          </app-readonly>

          <app-readonly title="Number of weeks payment into CIRT on behalf of employee"
            [value]="data.numberOfWeeksPayment " icon="tag" class="w-100 pr-1">
          </app-readonly>

          <app-readonly title="Total amount paid into CIRT" [value]="data.totalAmountDue " class="w-100 pr-1"
            icon="attach_money">
          </app-readonly>

          <app-readonly title="Total payment by employer" icon="attach_money" [value]="data.totalPaymentByEmployer "
            class="w-100 pr-1">
          </app-readonly>

          <h3 class="w-100 mt-3">TCR Scale</h3>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Weeks</th>
                <th scope="col"></th>
                <th scope="col">Weeks</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td>Less than 1 year old</td>
                <td>nil</td>
                <td>More than 6 years but not more than 7 years</td>
                <td>11</td>
              </tr>

              <tr>
                <td>1 year but not more than 2 years</td>
                <td>4</td>
                <td>More than 7 years but not more than 8 years</td>
                <td>13</td>
              </tr>

              <tr>
                <td>More than 2 years but not more than 3 years</td>
                <td>6</td>
                <td>More than 8 years but not more than 9 years</td>
                <td>14</td>
              </tr>

              <tr>
                <td>More than 3 years but not more than 4 years</td>
                <td>7</td>
                <td>More than 9 years but not more than 10 years</td>
                <td>16</td>
              </tr>

              <tr>
                <td>More than 4 years but not more than 5 years</td>
                <td>8</td>
                <td>More than 10 years</td>
                <td>12</td>
              </tr>

              <tr>
                <td>More than 5 years but not more than 6 years</td>
                <td>10</td>
                <td></td>
                <td></td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
      <!-- <div class="components-actions mt-4">
        <app-button loading class="float-right w-25" title="Save Details" icon="save" type="submit"
          [isDisabled]="data.isInvalid" (onClick)="onSubmitClick(data.value)">
        </app-button>
      </div> -->

    </div>
  </div>


</div>
