import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { editContribution_CanClone } from 'src/app/modules/shared/components/contribution-edit/selectors';

@Component({
  selector: 'app-contribution-edit-view',
  templateUrl: './contribution-edit.component.html',
  styleUrls: ['./contribution-edit.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class ContributionEditComponent extends ComponentBase implements OnInit, OnDestroy {
  contributionId: number = 0;
  canClone$ = this.store.pipe(select(editContribution_CanClone));

  constructor(public store: Store<AppState>,
    private wowService: NgwWowService,
    public activatedRoute: ActivatedRoute,
    private router: Router) {

    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.wowService.init();
    });

  }

  ngOnInit() {
    super.ngOnInitBase();

    this.getRouteParam(this.activatedRoute, 'contributionId', (id) => {
      this.contributionId = id;
    });

    this.screenWidthChange(this.store, (width: number) => {
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onCloneClick() {
    this.router.navigate(['add-contribution/copy', this.contributionId]);
  }

}
