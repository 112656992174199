import { EmployeeModel } from './../../../../model/employee.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const RequestAction = createAction('[Employee view] get data',
  props<{ id: number, employmentId: number }>());
export const ResponseAction = createAction('[Employee view] set data', props<{ payload: EmployeeModel }>());



