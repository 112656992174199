import { AppState } from 'src/app/store/app.states';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_IsLoading, commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { SMRTState } from '../../store/smrt.states';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { NgwWowService } from 'ngx-wow';
import { filter } from 'rxjs/operators';
import { mainLayout_Animated, mainLayout_Employer } from './selectors';
import { MainLayoutAnimatedAction, RequestEmployerInfoAction } from './actions';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';

@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  animated$ = this.store.pipe(select(mainLayout_Animated));
  employer$ = this.store.pipe(select(mainLayout_Employer));

  menuMode = "side";
  menuOpened = true;
  showMenuIconTop = true;

  @ViewChild("drawer") drawer: MatDrawer;

  constructor(public store: Store<AppState>,
    public smrtstore: Store<SMRTState>,
    public dialog: MatDialog,
    private titleService: Title,
    private wowService: NgwWowService,
    private router: Router) {
    super();
    this.titleService.setTitle("smart Moday – Employer Portal");

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.wowService.init();
    });
  }

  ngOnInit() {
    super.ngOnInitBase();
    // this.store.dispatch(RequestAction());
    this.store.dispatch(RequestEmployerInfoAction());

    this.screenWidthChange(this.store, (width: number) => {
      this.menuMode = "side";
      this.menuOpened = true;

      if (width < 1300) {
        this.menuMode = "over";
        this.menuOpened = false;
      }

    });

    setTimeout(() => {
      this.store.dispatch(MainLayoutAnimatedAction());
    }, 2000);

  }

  openedChange() {
    if (this.drawer) {
      this.menuOpened = this.drawer.opened;
    }
  }
  onSwipeToggle(event) {
    this.menuOpened = !this.menuOpened;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



  onSignoutClick(): void {
    this.store.dispatch(LogoutRequestAction());
    window.localStorage.removeItem('token_name');
    this.router.navigate(['login']);
  }

}
