
import { ChangePasswordModel } from '../../model/change-password.model';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { SubmitCompletedAction, SubmitAction } from './actions';
import { IChangePasswordState } from './state';

export const changePasswordFormName = 'changePasswordForm';

export const changePasswordState: IChangePasswordState = {
  form: createFormGroupState(changePasswordFormName, new ChangePasswordModel())
};

export const validateChangePasswordForm = updateGroup<ChangePasswordModel>({
  currentPassword: validate(required),
  newPassword: validate(required),
});

const reducer = createReducer(changePasswordState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),

  on(SubmitCompletedAction, (state) => {
    return {
      ...state,
    };
  }),
);

const changePasswordReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateChangePasswordForm,
);

export function changePasswordReducer(state: any | undefined, action: Action) {
  return changePasswordReducerFormState(state, action);
}
