import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ResetPasswordEffects } from './views/reset-password/effects';
import { LoginEffects } from './views/login/effects';
import { LoginComponent } from './views/login/login.component';
import { WebUserService } from './services/webuser.service';
import { PermissionGuardService } from './route-guard/permission-guard.service';
import { CommonEffects } from './store/common/common.effects';
import { HelperService } from './services/helper.service';
import { AccountsService } from './services/accounts.service';
import { HttpCustomInterceptor } from './services/http-interceptor';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { reducers } from './store/app.reducers';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './app.material.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuardService } from './route-guard/auth-guard.service';
import { TimeagoModule } from 'ngx-timeago';
import { clearState } from './store/meta-reducer';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { GetTenantModule } from './tenant.helper';
import { EmployerPortalSharedModule } from './modules/shared/shared.module';
import { ChangePasswordEffects } from './views/change-password/effects';
import { UserServiceConfig } from '@ifaa-components/ui-components/lib/services/userServiceConfig';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgwWowModule } from 'ngx-wow';
import { EmployerDetailsComponent } from './views/employer-details/employer-details.component';
import { EPEmployerService } from './services/EmployerPortalServices/ep-employer.service';
import { EmployerEditComponent } from './views/employer-edit/employer-edit.component';
import { EmployeeDetailsComponent } from './views/employee-details/employee-details.component';
import { EPEmployeeService } from './services/EmployerPortalServices/ep-employee.service';
import { EmployerDetailsViewComponent } from './views/employee-view/employee-view.component';
import { EmployeeEditComponent } from './views/employee-edit/employee-edit.component';
import { ContributionListViewComponent } from './views/contribution-view/contribution-view.component';
import { ContributionEditComponent } from './views/contribution-edit/contribution-edit.component';
import { EPContributionService } from './services/EmployerPortalServices/ep-contribution.service';
import { CertificateOfEmploymentEditComponent } from './views/certificate-of-employment-edit/certificate-of-employment-edit.component';
import { EPCertificateService } from './services/EmployerPortalServices/ep-certificate.service';
import { CertificateOfEmploymentViewComponent } from './views/certificate-of-employment-view/certificate-of-employment-view.component';
import { EmployeeAddComponent } from './views/employee-add/employee-add.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ForgotPasswordEffects } from './views/forgot-password/effects';
import { NewEmployeeDetailsViewComponent } from './views/new-employee-view/new-employee-view.component';
import { MapFileEditViewComponent } from './views/mapfile-edit-view/mapfile-edit-view.component';
import { ProcessedContributionViewComponent } from './views/processed-contribution-view/processed-contribution-view.component';
import { FeatureFlagGuardService } from './route-guard/feature-flag-guard.service';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { ScrollingModule } from '@angular/cdk/scrolling';

export const metaReducers: MetaReducer<any>[] = [clearState];
Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagEnv,
  enabledReleaseStages: ['Production', 'Staging', 'Uat', 'unknown'],
  onError: function (event) {
    event.addMetadata('tenant', {
      tenant: environment.code
    })
  }
});
export const MyDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 500,
  'width': 500,
  'placement': 'bottom'
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

const myImports = [
  NgrxFormsModule,
  CommonModule,
  HammerModule,
  FormsModule,
  NgwWowModule,
  ScrollingModule,
  TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
  RecaptchaV3Module,
  IfaaUiComponentsModule.forRoot(
    {
      entryAppearance: 'outline',
      showEntryPlaceholder: false
    } as UserServiceConfig),
  EmployerPortalSharedModule,
  ToastrModule.forRoot({
    timeOut: 6000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  }),
  MaterialModule,
  HttpClientModule,
  TimeagoModule.forRoot(),
  BrowserAnimationsModule,
  AppRoutingModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  StoreModule.forRoot(reducers, { metaReducers }),
  StoreDevtoolsModule.instrument({
    maxAge: 20, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  ReactiveFormsModule,
  EffectsModule.forRoot([
    LoginEffects,
    CommonEffects,
    ResetPasswordEffects,
    ChangePasswordEffects,
    ForgotPasswordEffects
  ])];

GetTenantModule.getModule(myImports);

@NgModule({
  declarations: [
    DashboardComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AppComponent,
    EmployerDetailsComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    EmployerEditComponent,
    EmployeeAddComponent,
    MapFileEditViewComponent,
    EmployeeDetailsComponent,
    EmployerDetailsViewComponent,
    EmployeeEditComponent,
    NewEmployeeDetailsViewComponent,
    ContributionListViewComponent,
    ContributionEditComponent,
    ProcessedContributionViewComponent,
    CertificateOfEmploymentEditComponent,
    CertificateOfEmploymentViewComponent
  ],
  entryComponents: [
  ],
  imports: myImports,
  providers: [
    AuthGuardService,
    AccountsService,
    HelperService,
    PermissionGuardService,
    FeatureFlagGuardService,
    WebUserService,
    EPEmployerService,
    EPEmployeeService,
    EPContributionService,
    EPCertificateService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaApi }
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
