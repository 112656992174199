import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AddressModel, Helper } from '@ifaa-components/ui-components';
import { getEmployerView_Model } from './selectors';

@Component({
  selector: 'app-employer-view',
  templateUrl: './employer-view.component.html',
  styleUrls: ['./employer-view.component.scss']
})
export class EmployerViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(getEmployerView_Model));
  @Input() itemclass: string = 'w-50';
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper = new Helper();
  constructor(public store: Store<EmployerPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
