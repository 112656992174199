import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { IResetPasswordState } from './state';

export const getResetPassword = (state: AppState) => state.resetPasswordState;

export const resetPassword_Form = createSelector(
  getResetPassword,
  (state: IResetPasswordState) => state.form
);
