import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { NewEmployeeModel } from 'src/app/model/employee.model';
import { INewEmployeeViewState } from './state';

export const initialState: INewEmployeeViewState = {
  model: new NewEmployeeModel()
};

const reducer = createReducer(initialState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  })
);

export function newEmployeeViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
