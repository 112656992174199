import { editCertificateOfEmployment_Employee, editCertificateOfEmployment_Form, editCertificateOfEmployment_LastModified, editCertificateOfEmployment_Validated } from './selectors';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, ResetFormAction, SubmitRequestAction, ValidateFormAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';
import { commomState_YesNoList } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { DisableAction } from 'ngrx-forms';

@Component({
  selector: 'app-certificate-of-employment-edit',
  templateUrl: './certificate-of-employment-edit.component.html',
  styleUrls: ['./certificate-of-employment-edit.component.scss']
})
export class CertificateOfEmploymentEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editCertificateOfEmployment_Form));
  lastModified$ = this.store.pipe(select(editCertificateOfEmployment_LastModified));
  yesNo$ = this.appStore.pipe(select(commomState_YesNoList));
  validated$ = this.appStore.pipe(select(editCertificateOfEmployment_Validated));
  employee$ = this.appStore.pipe(select(editCertificateOfEmployment_Employee));
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  employeeFullName: string;
  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appStore: Store<AppState>,
    public activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.getRouteParam(this.activatedRoute, 'employmentId', (id) => {
      this.dispatch(this.store, RequestAction({ id: id }));
    });

    this.sub = this.employee$.subscribe(name => {
      if (!this.employeeFullName && name !== '')
        this.employeeFullName = name;
    })

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });

        this.dispatch(this.store, ResetFormAction());
      }
    });
    this.sub = this.validated$.subscribe(validated => {
      if (!validated)
        this.dispatch(this.store, ValidateFormAction());
    });
    this.sub = this.form$.subscribe(async x => {
      if (x) {
        this.store.dispatch(new DisableAction(x.controls.totalAmountDue.id));
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: CertificateOfEmploymentModel) {
    this.dispatch(this.store, SubmitRequestAction({ payload: data }));
  }

  onGoToDetailsClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/employee-details']);
  }

}
