import { editEmployee_Form, editEmployee_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, ResetFormAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeModel } from 'src/app/model/employee.model';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editEmployee_Form));
  lastModified$ = this.store.pipe(select(editEmployee_LastModified));

  constructor(public store: Store<EmployerPortalSharedState>,
    public activatedRoute: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['employee-details']);
        this.dispatch(this.store, ResetFormAction());
      }
    });
    this.getRouteParam(this.activatedRoute, 'id', (id) => {
      this.getRouteParam(this.activatedRoute, 'employmentId', (employmentId) => {
        this.dispatch(this.store, RequestAction({ id, employmentId }));
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: EmployeeModel) {
    this.dispatch(this.store, SubmitRequestAction({ payload: data }));

  }

}
