import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INewEmployeeRequestListViewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const selector = createSelector(state, (state) => state.newEmployeeRequestListView);

export const newEmployeeListView_List = createSelector(
  selector,
  (state: INewEmployeeRequestListViewState) => state.list
);

export const newEmployeeListView_Filter = createSelector(
  selector,
  (state: INewEmployeeRequestListViewState) => state.filter
);

export const newEmployeeListView_HasNewEmployee = createSelector(
  selector,
  (state: INewEmployeeRequestListViewState) => state.hasNewEmployee
);
