import { AppState } from './../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';

@Injectable()
export class PermissionGuardService implements CanActivate {

  constructor(public store: Store<AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let role = route.data.permission as string;
    return new Observable<boolean>(obs => {
    });
  }
}
