import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EPEmployeeService } from 'src/app/services/EmployerPortalServices/ep-employee.service';
import { EPCertificateService } from 'src/app/services/EmployerPortalServices/ep-certificate.service';

@Injectable()
export class ViewCertificateOfEmploymentEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private serviceEmployee: EPEmployeeService,
    private certService: EPCertificateService
    ) {
  }

  getById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.certService.getExistingEmployeeCertificate(action.id)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));


}
