import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';

@Injectable()
export class EPCertificateService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getNewEmployeeCertificate(employmentId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/certificate/${employmentId}/new`, { headers: this.headers() });
  }
  
  getExistingEmployeeCertificate(certificateId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/certificate/${certificateId}`, { headers: this.headers() });
  }

  submit(model: CertificateOfEmploymentModel): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/certificate/`, model, { headers: this.headers() });
  }
}
