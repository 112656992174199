<div id="employee-view-container">
  <div class="card">
    <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">
      <app-readonly title="Employee Name" [value]="data.fullName" class="{{itemclass}} p-1 flex-fill w-100"></app-readonly>

      <app-readonly title="Employee Number" [value]="data.employeeNumber" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly title="Date of Birth" value="{{data.dateOfBirth | date:'dd/MM/yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Email" [value]="data.email" class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Home Phone" [value]="data.homePhone" class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Mobile" [value]="data.mobile" class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Occupation" [value]="data.occupation" class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly class="w-100" title="Postal Address" [value]="data.postalAddress.fullAddress"
      class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly class="w-100" title="Residential Address" [value]="data.residentialAddress.fullAddress"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Employment Start Date" value="{{data.employment.startDate | date:'dd/MM/yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Employment End Date" value="{{data.employment.endDate | date:'dd/MM/yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="EBA" value="{{data.eba}}" class="{{itemclass}} p-1 flex-fill"> </app-readonly>

      <app-readonly title="Contribution Balance" value="{{data.contributionBalance | currency}}"
        class="{{itemclass}} p-1 flex-fill"> </app-readonly>

    </div>

  </div>

</div>
