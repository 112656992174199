<div id="main-layout-container" class="d-flex flex-column min-h-100 cirt-theme" screen-width>
  <!--  -->

  <mat-progress-bar mode="indeterminate" class="position-fixed fixed-top" *ngIf="isLoading$ | async"></mat-progress-bar>
  <div class="dashboard-top-bg shadow-sm">
    <nav class="navbar-primary top " (swipeleft)="onSwipeToggle($event)">
      <div class="opacity-bg"></div>
      <div class="container d-flex flex-row p-2">
        <div class="navbar-brand card">
          <img src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/logocirt.png">
        </div>
        <div class="d-flex card-container ">
          <div class="card flex-fill  fadeInUp option4 flex-row " [ngClass]="{'wow': !(animated$ | async)}"
            routerLink="/dashboard" [routerLinkActive]="['selected']">
            <!-- <div class="icon ">
              <mat-icon>home</mat-icon>
            </div> -->
            <h4>Dashboard</h4>
          </div>
          <div class="card flex-fill fadeInUp option4 flex-row" data-wow-delay=".1s" routerLink="/employer-details"
            [routerLinkActive]="['selected']" [ngClass]="{'wow': !(animated$ | async)}">
            <!-- <div class="icon">
              <mat-icon>work</mat-icon>
            </div> -->
            <h4>Employer Details</h4>
          </div>
          <div class="card flex-fill fadeInUp option4 flex-row" data-wow-delay=".1s" routerLink="/employee-details"
            [routerLinkActive]="['selected']" [ngClass]="{'wow': !(animated$ | async)}">
            <!-- <div class="icon">
              <mat-icon>person</mat-icon>
            </div> -->
            <h4>Employee Details</h4>
          </div>
          <div class="card flex-fill  fadeInUp option4 flex-row" data-wow-delay=".2s" routerLink="/contribution-list"
            [routerLinkActive]="['selected']" [ngClass]="{'wow': !(animated$ | async)}">
            <!-- <div class="icon">
              <mat-icon>attach_money</mat-icon>
            </div> -->
            <h4>Contribution</h4>
          </div>
          <!-- <div class="card flex-fill  fadeInUp option4 flex-row" data-wow-delay=".3s" [routerLinkActive]="['selected']"
            [ngClass]="{'wow': !(animated$ | async)}">

            <h4>JETCO</h4>
          </div> -->

        </div>
        <div class="flex-fill d-flex justify-content-end align-items-center" style="z-index: 100;"
          *ngIf="employer$ | async; let employer">

          <button class="btn btn-secondary dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <mat-icon class="mr-2">person</mat-icon>

            <div class="employer-info">
              <div class="name">{{employer.name}}</div>
              <div class="contact">{{employer.primaryContact?.firstName}} {{employer.primaryContact?.lastName}}</div>
            </div>

          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item d-flex align-items-center p-2 click" [routerLink]="'/change-password'">
                <mat-icon class="mr-3">lock</mat-icon>
                Change password
              </a>
            </li>
            <li >
              <div class="dropdown-item d-flex align-items-center p-2 signout click d" (click)="onSignoutClick()">
                <mat-icon class="mr-3">exit_to_app</mat-icon>
                Sign out
              </div>
            </li>

          </ul>
        </div>

      </div>
    </nav>
    <nav class="top navbar navbar-expand-lg container">

      <!-- <div class="navbar-brand logo flex-fill">
      <button class="btn btn-link toggle-menu no-text-decoration" (click)="drawer.toggle()"
        *ngIf="screenWidth>=992">
        <mat-icon>
          menu_open
        </mat-icon>
      </button>


    </div> -->


    </nav>
  </div>
  <div class="main-content container">

    <div class="navbar-global-wrapper">
      <nav class="navbar navbar-inverse navbar-global navbar-fixed-top">
        <div class="container">
          <div class="navbar-header d-flex flex-row w-100">


          </div>

        </div>
      </nav>
    </div>


    <main class="container d-flex flex-fill main-content p-2" (swiperight)="onSwipeToggle($event)">
      <app-scroll-up></app-scroll-up>
      <router-outlet name="master"></router-outlet>
    </main>
    <app-footer></app-footer>
    <nav class="navbar-secondary toolbar-bottom d-flex align-items-center flex-row" *ngIf="screenWidth<992"
      appanimatecss="animate__fadeInUp animate__delay-1s">
      <div class="d-flex flex-fill option flex-column align-items-center" (click)="drawer.toggle()">
        <mat-icon>menu_open</mat-icon>
        <label>Menu</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/">
        <mat-icon>house</mat-icon>
        <label>Overview</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/personal-details">
        <mat-icon>person</mat-icon>
        <label>Profile</label>
      </div>
    </nav>
  </div>
  <!-- <mat-drawer-container class="flex-fill d-flex" autosize>
    <mat-drawer #drawer class="sidenav" [mode]="menuMode" [opened]="menuOpened" [openedChange]="openedChange()">


    </mat-drawer>
    <mat-drawer-content class="d-flex flex-fill full overflow-x-hidden flex-column main">




    </mat-drawer-content>
  </mat-drawer-container> -->

</div>
