<div class="page-component d-flex {{compclass}}" [attr.id]="fragmentid"
  [class.blink]="fragmentid == currentFragment && currentFragment && fragmentid">

  <div class="content flex-fill d-flex flex-column w-100">
    <div class="d-flex flex-row align-items-center">
      <div class="flex-fill d-flex align-items-center header {{headerclass}}" *ngIf="icon || title">
        <mat-icon *ngIf="icon" class="toolbarheader-icon">{{icon}}</mat-icon>
        <label class="title">{{title}}</label>
        <app-tooltip [text]="help"></app-tooltip>
      </div>
      <div class="top-actions">
        <ng-content class="align-items-end" select="[slot=top-actions]"></ng-content>
      </div>
    </div>
    <ng-content class="align-items-stretch d-flex " select="[slot=start]"></ng-content>
    <ng-content class="align-items-end" select="[slot=actions]"></ng-content>
  </div>



</div>
