import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.states';
import { commonReducer } from './common/common.reducer'
import { loginReducer } from '../views/login/reducer'
import { forgotPasswordReducer } from '../views/forgot-password/reducer'
import { changePasswordReducer } from '../views/change-password/reducer'
import { resetPasswordReducer } from '../views/reset-password/reducer'

export const reducers: ActionReducerMap<AppState> = {
  loginState: loginReducer,
  commonState: commonReducer,
  forgotPasswordState: forgotPasswordReducer,
  changePasswordState: changePasswordReducer,
  resetPasswordState: resetPasswordReducer,
};
