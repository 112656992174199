import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { IEmployerEditState } from './state';
import { RequestAction, ResetFormAction, ResponseAction, SubmitResponseAction } from './actions';
import { EmployerModel } from 'src/app/model/employer.model';
import { AddressModel } from '@ifaa-components/ui-components';
import { EmployerContactModel } from 'src/app/model/employer-contact.model';

export const formName = 'EmployerEditForm';

export const state: IEmployerEditState = {
  form: createFormGroupState(formName, new EmployerModel()),
  lastModifiedTime: null
};

export const validateEditEmployerForm = updateGroup<EmployerModel>({
  // firstName: validate(required),
  // lastName: validate(required),
  primaryContact: updateGroup<EmployerContactModel>({
    email: validate(required, email)
  })
  // dateOfBirth: validate(required),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: setValue(new EmployerModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue(new EmployerModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new EmployerModel(), payload,
      {
        postalAddress: Object.assign(new AddressModel(), payload.postalAddress),
        businessAddress: Object.assign(new AddressModel(), payload.businessAddress),
        primaryContact: Object.assign(new EmployerContactModel(), payload.primaryContact)
      }
      ))(state.form),
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const editEmployerReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditEmployerForm,
);

export function employerEditReducer(state: any | undefined, action: Action) {
  return editEmployerReducerFormState(state, action);
}
