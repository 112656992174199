import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IMapFileEditState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const mapFileState = createSelector(state, (state) => state.mapfileEditState);

export const mapFileState_Form = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.form
);

export const mapFileState_LastModified = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.lastModifiedTime
);

export const mapFileState_CurrentStep = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.currentStep
);

export const mapFileState_TotalSteps = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.totalSteps
);

export const mapFileState_Validated = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.validated
);

export const mapFileState_mapToOptionsKV = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.mapToOptions.map(x => { return { key: x.key, value: x.value } })
);

export const mapFileState_mapToOptions = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.mapToOptions
);

export const mapFileState_contributionCheck = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.contributionCheck
);

export const mapFileState_totalColumn = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.totalColumn
);

export const mapFileState_Columns = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.columns.slice(0, state.totalColumn)
);

export const mapFileState_Contribution = createSelector(
  mapFileState,
  (state: IMapFileEditState) => state.contribution
);
