import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { IProcessedContributionListViewState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const selector = createSelector(state, (state) => state.processedContributionsListViewState);

export const processedContributionsView_List = createSelector(
  selector,
  (state: IProcessedContributionListViewState) => state.list
);

export const processedContributionsView_CurrentPage = createSelector(
  selector,
  (state: IProcessedContributionListViewState) => state.currentPage
);
