import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployerViewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const getemployerView = createSelector(state, (state) => state.employerViewState);

export const getEmployerView_Model = createSelector(
  getemployerView,
  (state: IEmployerViewState) => state.model
);
