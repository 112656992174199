<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Dashboard</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex flex-row">

      <app-employer-view-overview class="w-50 d-flex flex-row flex-fill mr-4"></app-employer-view-overview>


      <div class="d-flex flex-row flex-fill">
        <div class="card d-flex flex-fill bg-image">
          <!-- <h4 class="card-title"> Henrique</h4>
          <div class="card-body">
            Test
          </div> -->

        </div>
      </div>
    </div>

    <div class="d-flex flex-row mt-3">

      <app-component-wrapper class="mt w-100" icon="" title="Draft Contributions"
        [hidden]="(draftContributionList$ |async)?.length == 0">
        <div class="d-flex flex-column" slot="start">
          <app-draft-contributions [fullpage]="false" class="floating-add-contribution"></app-draft-contributions>
        </div>
        <div class="d-flex flex-column" slot="top-actions">
          <app-button color="light" title="Add Contribution" icon="add" routerLink="/add-contribution"></app-button>
        </div>
      </app-component-wrapper>
    </div>

    <div class="d-flex flex-row mt-3">

      <app-component-wrapper class="mt w-100" icon="" title="Submitted Contributions" [help]="'employerPortal_Submitted_Contributions' | message | async">
        <div class="d-flex flex-column" slot="start">
          <app-submitted-contributions [fullpage]="false" class="floating-add-contribution">
          </app-submitted-contributions>
        </div>
        <div class="d-flex flex-column" slot="top-actions">
          <app-button loading color="light" title="Add Contribution" icon="add" type="submit"
            routerLink="/add-contribution"></app-button>
        </div>
      </app-component-wrapper>
    </div>

    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="Processed Contributions" [help]="'employerPortal_Processed_Contributions' | message | async">
        <div class="d-flex flex-column" slot="start">
          <app-processed-contributions-list [fullpage]="false" class="floating-add-contribution"></app-processed-contributions-list>
        </div>
     </app-component-wrapper>
    </div>

    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="Employees">
        <div class="d-flex flex-column" slot="start">
          <app-employee-list-view ></app-employee-list-view>
        </div>
        <div class="d-flex flex-column" slot="top-actions">
          <app-button loading color="light" title="Add Employee" icon="add" type="submit" routerLink="/employee-add">
          </app-button>
        </div>
      </app-component-wrapper>
    </div>

    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="New Employee Requests" *ngIf="(hasNewEmployees$ | async)">
        <div class="d-flex flex-column" slot="start">
          <app-new-employee-list-view [fullpage]="false"></app-new-employee-list-view>
        </div>
      </app-component-wrapper>
    </div>
  </div>
</div>
