import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, onNgrxFormsAction, SetValueAction, setValue} from 'ngrx-forms';
import { ICertificateOfEmploymentEditState } from './state';
import { RequestAction, ResetFormAction, ResponseAction, SubmitResponseAction, ValidateFormAction } from './actions';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';
import { Helper } from '@ifaa-components/ui-components';
import { required } from 'ngrx-forms/validation';
import { dateGreaterThanOrEqualTo } from '../../validation/date-greater-than.validation';

export const formName = 'CertificateOfEmploymentEditForm';
var helper = new Helper();

export const state: ICertificateOfEmploymentEditState = {
  form: createFormGroupState(formName, new CertificateOfEmploymentModel()),
  lastModifiedTime: null,
  validated: false
};

export const formNo = updateGroup<CertificateOfEmploymentModel>({
  certifyNoDetails: validate(required)
});

export const formYes = updateGroup<CertificateOfEmploymentModel>({
});

export const validateEndDate = updateGroup<CertificateOfEmploymentModel>({
  employmentEndDate: (state, parentState) => {

    state = validate(state, required, dateGreaterThanOrEqualTo(parentState.controls.employmentStartDate.value) );
    return state;
  }
});

function ValidateStep(state: ICertificateOfEmploymentEditState, action) {
  let formState = createFormGroupState(formName, state.form.value);
  let validatedForm = null;

  validatedForm = validateEndDate(formState);

  if (!state.form.value.agreeToCertify) {
    validatedForm = formNo(formState);
  }
  
  if (validatedForm) {
    return {
      ...state,
      form: validatedForm,
      validated: true
    };
  }
  return state;
}

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return ValidateStep(state, action);
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new CertificateOfEmploymentModel()), 
      lastModifiedTime: null,
      validated: false
    };
  }),
  on(ValidateFormAction, (state) => {
    var clone = helper.clone(state)
    return ValidateStep(clone, ValidateFormAction);
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new CertificateOfEmploymentModel()),
      lastModifiedTime: null,
      validated: false

    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      validated: false,
      form: setValue(Object.assign(new CertificateOfEmploymentModel(), payload))(state.form),
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

export function certificateOfEmploymentEditReducer(state: any | undefined, action: Action) {

  return reducer(state, action);

}


