import { EmployerModel } from './../../../../model/employer.model';
import { editEmployer_Form, editEmployer_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, ResetFormAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employer-edit',
  templateUrl: './employer-edit.component.html',
  styleUrls: ['./employer-edit.component.scss']
})
export class EmployerEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editEmployer_Form));
  lastModified$ = this.store.pipe(select(editEmployer_LastModified));

  constructor(public store: Store<EmployerPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());
    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['employer-details']);
        this.dispatch(this.store, ResetFormAction());
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: EmployerModel) {
    this.dispatch(this.store, SubmitRequestAction({ payload: data }));

  }

}
