import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ILoginState } from './state';

export const getLogin = (state: AppState) => state.loginState;

export const getLogIn_Form = createSelector(
  getLogin,
  (state: ILoginState) => state.form
);

export const getLogIn_isUnavailable = createSelector(
  getLogin,
  (state: ILoginState) => state.isUnavailable
);

export const getLogIn_isUnavailableMessage = createSelector(
  getLogin,
  (state: ILoginState) => state.isUnavailableMessage
);
