<div id="new-employee-view-container">
  <div class="card">
    <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">
      <app-readonly title="Employee Name" [value]="data.fullName" class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Reference Number" [value]="data.referenceNumber" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>

      <app-readonly class="w-100" title="Residential Address" [value]="helper.formatAddress(data.residentialAddress)"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>
        
      <app-readonly title="Date of Birth" value="{{data.dateOfBirth | date:'dd/MM/yyyy'}}"
      class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="Employment Start Date" value="{{data.employmentStartDate | date:'dd/MM/yyyy'}}"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly title="EBA" value="{{data.eba}}" class="{{itemclass}} p-1 flex-fill"> </app-readonly>

      <app-readonly title="Contribution Rate" value="{{data.contributionRate | number: '1.2-2' }}" class="{{itemclass}} p-1 flex-fill"> </app-readonly>
    </div>
  </div>
</div>
