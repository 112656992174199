<div id="change-password-container">
  <div class="page-header">
    <app-toolbar-options icon="lock_open">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Change Password</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex">
      <div class="w-50 pr-4">
        <form [ngrxFormState]="form" (ngSubmit)="onSubmit(form.value)" *ngIf="form$ | async; let form"
          role="presentation">
          <app-component-wrapper icon="lock" title="Change your password" class="w-100">
            <div class="d-flex flex-column" slot="start">
              <div class="card">
                <div class="row">
                  <div class="col col-md-12">
                    <app-entry class="entry-dark-bg" title="{{ 'CURRENT_PASSWORD' | translate }}" autofill="true"
                      [control]="form.controls.currentPassword" entrytype="password" icon="chevron_right">
                    </app-entry>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-md-12">
                    <app-entry class="entry-dark-bg" title="{{ 'NEW_PASSWORD' | translate }}" autofill="true"
                      [control]="form.controls.newPassword" entrytype="password" icon="chevron_right">
                    </app-entry>
                  </div>
                </div>

              </div>
            </div>
            <div class="card d-flex flex-row-reverse components-actions" slot="actions" *ngIf="!(elevatedAccess$ | async)">
              <app-button loading class="" icon="{{'BTN.change-password.submit.icon' | translate}}" type="submit"
                title="{{ 'SUBMIT' | translate }}" [isDisabled]="form.isInvalid">
              </app-button>
            </div>
          </app-component-wrapper>
        </form>

      </div>
      <div class="w-50">
        <app-component-wrapper icon="info" title="Password Security" headerclass="danger">
          <div class="d-flex flex-column card" slot="start">
            <ul class="description">
              <li>A password should be 8 characters or more</li>
              <li>A password should include a combination of letters, numbers, and characters.</li>
              <li>A password shouldn’t be shared with any other account.</li>
              <li>A password shouldn’t include any of the user’s personal information like their address or phone
                number. It’s also best not to include any information that can be accessed on social media like kids’ or
                pets’ names.</li>
              <li>A password shouldn’t contain any consecutive letters or numbers.</li>
              <li>A password shouldn’t be the word “password” or the same letter or number repeated.</li>
            </ul>
          </div>

        </app-component-wrapper>
      </div>
    </div>

  </div>
</div>
