<div id="login-container" class="d-flex ">
  <div class="d-flex w-100">
    <div class="left-side">

<div class="left-side-content"></div>
    </div>
    <div class="right-side flex-fill d-flex align-items-center">
      <form [ngrxFormState]="form" (ngSubmit)="loginUser(form.value)" *ngIf="form$ | async; let form"
        class=" d-flex  w-100 justify-content-center" role="presentation">
        <div class="backdrop"></div>
        <div appanimatecss class="wrapper">
          <div class="p-3 ">
            <a target="_blank" href="{{'website' | tenantconfig | async }}"><img
                src="{{'fundLogo' | tenantconfig | async }}" class="logo" style="height: 70px;width: auto;"></a>

            <!-- <img src="{{'fundLogo' | tenantconfig | async }}" class="logo mobile"> -->
            <div class="page-title">

            </div>
            <div class=" d-flex flex-row w-100">

              <div class="flex-fill d-flex flex-column w-100">

                <h1 class="card-title mb-3 ">Employer Portal</h1>

                <div class="alert alert-warning" *ngIf="isUnavailable$ | async">
                  {{ isUnavailableMessage$ | async}}
                </div>
                <div class="alert alert-info mb-2" *ngIf="'employerPortal_Login_Tooltip' | message | async let tooltip" [innerHTML]="tooltip">
                </div>
                <div class="card-body flex-fill d-flex align-items-center" *ngIf="!(isUnavailable$ | async)">

                  <div class="main-container w-100">
                    <div class="content animated fadeInUpSmall">
                      <div class="row">
                        <div class="col col-md-12">
                          <app-entry class="entry-dark-bg" [title]="'loginScreen_UsernameTitle'|message|async"
                            autofill="true" [control]="form.controls.username" icon="account_box">
                          </app-entry>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col col-md-12">
                          <app-entry class="entry-dark-bg" title="{{ 'PASSWORD' | translate }}" autofill="true"
                            [control]="form.controls.password" entrytype="password" icon="lock">
                          </app-entry>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <a class="forgot d-block mb-4 float-right" routerLink="/forgot-password">{{"FORGOT_PASSWORD" |
                            translate}}</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-bottom" *ngIf="!(isUnavailable$ | async)">
                  <app-button loading class="" type="submit" title="{{ 'LOGIN' | translate }}" icon="east"
                    [isDisabled]="form.isInvalid">
                  </app-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>



</div>
