import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import { forgotPassword_Form } from './selectors';
import { SubmitRequestAction } from './actions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(forgotPassword_Form));

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmit(data) {
    this.store.dispatch(SubmitRequestAction({ payload: data }));
  }
}
