<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="person">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Employee Details</div>
      </div>
      <div class="d-flex flex-column" slot="end">
        <div class="d-flex flex-row align-items-center">
          <app-tooltip [text]="'employerPortal_Add_Employee'|message|async"></app-tooltip>
          <app-button class=" ml-1 float-right" title="Add Employees" icon="add" (onClick)="onAddEmployeeClick()">
          </app-button>
        </div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-employee-list-view></app-employee-list-view>
      </div>
      <div class="m-3 components-actions" slot="actions">

      </div>
    </app-component-wrapper>
  </div>
</div>
