// import { MemberModel } from '../../../model/member.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { EmployerModel } from 'src/app/model/employer.model';

// export const MainExpandedRequestAction = createAction('[Main View] expand item',
//   props<{ menuItem: MenuModel }>());

// export const MainSearchIncrementPageAction = createAction('[Main View] add 1 to current page');

export const MainLayoutAnimatedAction = createAction('[Main View] animated');

export const RequestEmployerInfoAction = createAction('[Main View] get EmployerInfo');
export const ResponseEmployerInfoAction = createAction('[Main View] set EmployerInfo data',  props<{ payload: EmployerModel }>());
