import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction
} from 'ngrx-forms';
import { LoadMoreAction, ResponseAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { INewEmployeeRequestListViewState, NewEmployeeRequestListViewFilter } from './state';
var helper = new Helper();

export const employeeListFilter = 'newEmployeeRequestListFilter';

export const state: INewEmployeeRequestListViewState = {
  list: [],
  filter: createFormGroupState(employeeListFilter, new NewEmployeeRequestListViewFilter()),
  hasNewEmployee: false
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId == 'newEmployeeRequestListFilter.q') {
      var newFilter = {
        ...state.filter.value,
        currentPage: 0
      };
      return {
        ...state,
        filter: setValue(newFilter)(state.filter)
      };
    }
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.filter.value);
    clone.currentPage = payload.length == 0 ? -1 : state.filter.value.currentPage;

    return {
      ...state,
      hasNewEmployee: state.filter.value.currentPage === 0 && state.filter.value.q == '' && payload.length > 0,
      list: state.filter.value.currentPage === 0 ? payload : state.list.concat(payload),
      filter: setValue(clone)(state.filter),
    }

  }),
  on(LoadMoreAction, (state) => {

    if (state.filter.value.currentPage === -1)
      return state;

    var clone = helper.clone(state.filter.value);

    clone.currentPage = state.filter.value.currentPage + 1;

    return {
      ...state,
      filter: setValue(clone)(state.filter)
    };
  })
);

export function newEmployeeRequestListViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
