import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { EmployeeModel, NewEmployeeModel } from 'src/app/model/employee.model';

@Injectable()
export class EPEmployeeService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getEmployee(id: number, employmentId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/Employee/${id}/employment/${employmentId}`, { headers: this.headers() });
  }

  getNewEmployee(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/Employee/new/${id}`, { headers: this.headers() });
  }

  updateEmployeeDetails(model: EmployeeModel): Observable<any> {
    return this.http.put(environment.apiUrl + `/employer-portal/Employee`, model, { headers: this.headers() });
  }

  addEmployeeDetails(model: NewEmployeeModel): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/Employee`, model, { headers: this.headers() });
  }
}
