
import { IResetPasswordState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ResetPasswordModel } from '../../model/reset-password.model';
import { ResetPasswordResponseAction, SetTokenAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'resetPasswordForm';
var helper = new Helper();

export const loginState: IResetPasswordState = {
  form: createFormGroupState(formName, new ResetPasswordModel())
};

export const validateLoginForm = updateGroup<ResetPasswordModel>({
  username: validate(required),
  password: validate(required),
  token: validate(required),
});

const reducer = createReducer(loginState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetPasswordResponseAction, (state) => {
    return {
      ...state,
    };
  }),
  on(SetTokenAction, (state, {payload}) => {
    var clone = helper.clone(state.form.value);
    clone.token = payload;
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateLoginForm,
);

export function resetPasswordReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
