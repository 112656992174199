import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-processed-contribution-view',
  templateUrl: './processed-contribution-view.component.html',
  styleUrls: ['./processed-contribution-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class ProcessedContributionViewComponent extends ComponentBase implements OnInit, OnDestroy {
  contributionId: number = 0;

  constructor(public store: Store<AppState>,
    private wowService: NgwWowService,
    public activatedRoute: ActivatedRoute,

    private router: Router
  ) {
    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.wowService.init();
    });

  }

  ngOnInit() {
    super.ngOnInitBase();
    this.getRouteParam(this.activatedRoute, 'contributionId', (id) => {
      this.contributionId = id;
    });

    this.screenWidthChange(this.store, (width: number) => {
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onCloneClick() {
    this.router.navigate(['add-contribution/copy', this.contributionId]);

  }

}
