import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AddressModel, Helper } from '@ifaa-components/ui-components';
import { getEmployeeView_Model } from './selectors';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-view',
  templateUrl: './employee-view.component.html',
  styleUrls: ['./employee-view.component.scss']
})
export class EmployeeViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(getEmployeeView_Model));
  @Input() itemclass: string = 'w-50';
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper = new Helper();
  constructor(public store: Store<EmployerPortalSharedState>,
    public activatedRoute: ActivatedRoute,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.getRouteParam(this.activatedRoute, 'id', (id) => {
      this.getRouteParam(this.activatedRoute, 'employmentId', (employmentId) => {
        this.dispatch(this.store, RequestAction({ id, employmentId }));

      });

    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
