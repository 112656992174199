import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms} from 'ngrx-forms';
import { IProcessedContributionState } from './state';
import { RequestAction, ResponseAction } from './actions';
import { ContributionModel } from 'src/app/model/contribution.model';


export const state: IProcessedContributionState = {
  data: new ContributionModel()
};

const reducer = createReducer(state,
  onNgrxForms(),
  on(RequestAction, (state): { data: ContributionModel; } => ({
    ...state,
    data: new ContributionModel()
  })),
  on(ResponseAction, (state, { payload }): { data: ContributionModel; } => ({
    ...state,
    data: payload,
  }))
);

export function processedContributionReducer(state: any | undefined, action: Action): IProcessedContributionState {
  return reducer(state, action);
}
