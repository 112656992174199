
import { EmployeeListViewFilter, IEmployeeListViewState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction
} from 'ngrx-forms';
import { LoadMoreAction, RequestAction, ResetAction, ResponseAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';
var helper = new Helper();

export const employeeListFilter = 'employeeListFilter';

export const state: IEmployeeListViewState = {
  list: [],
  filter: createFormGroupState(employeeListFilter, new EmployeeListViewFilter()),
  isLoading: false
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId == 'employeeListFilter.q') {
      var newFilter = {
        ...state.filter.value,
        currentPage: 0
      };
      return {
        ...state,
        filter: setValue(newFilter)(state.filter)
      };
    }
    return state;
  }),
  on(RequestAction, (state, { search, page }) => {
    return {
      ...state,
      isLoading: true
    }
  }),
  on(ResetAction, (state, { }) => {
    return {
      ...state,
      filter: createFormGroupState(employeeListFilter, new EmployeeListViewFilter()),
      isLoading: false
    }
  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.filter.value);
    clone.currentPage = payload.length == 0 ? -1 : state.filter.value.currentPage

    return {
      ...state,
      list: state.filter.value.currentPage === 0 ? payload : state.list.concat(payload),
      filter: setValue(clone)(state.filter),
      isLoading: false

    }

  }),
  on(LoadMoreAction, (state) => {

    if (state.filter.value.currentPage === -1)
      return state;

    var clone = helper.clone(state.filter.value);

    clone.currentPage = state.filter.value.currentPage + 1;

    return {
      ...state,
      filter: setValue(clone)(state.filter)
    };
  })
);

export function employeeListViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
