import { DraftContributionsViewComponent } from './../../../shared/components/draft-contributions/draft-contributions.component';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { RequestAction as DraftContributionRequestAction } from '../../../shared/components/draft-contributions/actions';
import { RequestAction as NewEmployeeRequestAction } from '../../../shared/components/new-employee-request-list-view/actions';
import { draftContributionsView_List } from 'src/app/modules/shared/components/draft-contributions/selectors';
import { newEmployeeListView_HasNewEmployee } from 'src/app/modules/shared/components/new-employee-request-list-view/selectors';

@Component({
  selector: 'app-tenant-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantDashboardComponent extends ComponentBase implements OnInit, OnDestroy {

  draftContributionList$ = this.store.pipe(select(draftContributionsView_List));
  hasNewEmployees$ = this.store.pipe(select(newEmployeeListView_HasNewEmployee));

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.screenWidthChange(this.store, (width: number) => {
    });

    this.store.dispatch(DraftContributionRequestAction({ page: 0 }));
    this.store.dispatch(NewEmployeeRequestAction({ search: '', page: 0 }));

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
