<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options backlink="">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">New Employee</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-employee-add></app-employee-add>
      </div>
    </app-component-wrapper>
  </div>
</div>
