import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { LoggedInModel } from 'src/app/model/logged-in.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';

export const ChangeScreenWidthAction = createAction('[Screen Width] set',
  props<{ payload: number}>());

  export const IsLoadingAction = createAction('[Is app loading] loading',
  props<{ payload: boolean }>());

export const SystemConfigurationRequestAction = createAction('[System Config] get data');

export const SystemConfigurationResponseAction = createAction('[System Config] set data',
  props<{ payload: SystemConfigModel }>());

export const IsLoggedInRequestAction = createAction('[User check] is logged in request');

export const IsLoggedInResponseAction = createAction('[User check] is logged in response',
  props<{ data: LoggedInModel }>());


export const LogoutRequestAction = createAction('[Logout request]');

