
import { IEmployerViewOverviewState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { EmployerModel } from 'src/app/model/employer.model';

export const loginState: IEmployerViewOverviewState = {
  model: new EmployerModel()
};

const reducer = createReducer(loginState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  })
);

export function employerViewOverviewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
