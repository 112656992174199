export const environment = {
  production: false,
  apiUrl: 'https://localhost:8001/api',
  code: '',
  dashboard: '/',
  bugsnagApiKey: '80b4e34f3c6cbc7b5a7201a9e610d65b',
  bugsnagEnv: 'Development',
  recaptchaApi: '6LfeW9saAAAAAPWtOiD0ly77pxy3mxibLWJd2-yH',
  ga: ''
};
