import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/app.states';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IsLoggedInRequestAction, SystemConfigurationRequestAction } from './store/common/common.actions';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DomSanitizer } from '@angular/platform-browser';
import { commomState_SystemConfig_Config } from './store/common/common.selectors';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '';

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  dynamicCSSUrl: string;
  constructor(public store: Store<AppState>,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private overlayContainer: OverlayContainer
  ) {
    translate.addLangs(['en', 'en.' + environment.code]);
    translate.setDefaultLang('en');
    translate.use('en.' + environment.code);
    this.overlayContainer.getContainerElement().classList.add('dark-theme');
    this.dynamicCSSUrl = '/modules/dynamic.css';

    this.store.dispatch(IsLoggedInRequestAction());
    this.store.dispatch(SystemConfigurationRequestAction());

    this.tenantConfig$.subscribe(x => {
      if (x)
        this.favIcon.href = x.favIcon;
    });
    this.setupGoogleTagManager()
  }

  setupGoogleTagManager() {
    if (!environment.ga) return;

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', environment.ga, { 'page_path': e.urlAfterRedirects });
    });

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.ga;
    document.head.prepend(script);
  }

}
