import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';


export const ResetFormAction = createAction('[Edit certificate-of-employment] reset form');

export const RequestAction = createAction('[Edit certificate-of-employment] request data by Id', props<{ id: number }>());

export const ResponseAction = createAction('[Edit certificate-of-employment] response data by Id',
  props<{ payload: CertificateOfEmploymentModel }>());

export const SubmitRequestAction = createAction('[Edit certificate-of-employment] save CertificateOfEmployment details',
  props<{ payload: CertificateOfEmploymentModel }>());

export const SubmitResponseAction = createAction('[Edit certificate-of-employment]  CertificateOfEmployment update completed',
  props<{ payload: OkModel<string> }>());

export const ValidateFormAction = createAction('[Edit certificate-of-employment] validate form');
