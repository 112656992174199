import { OkModel } from './../../model/ok.model';
import { LoginModel } from '../../model/login.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const LoginAction = createAction('[Login View] log in',
  props<{ payload: LoginModel }>());

export const LoginCompletedAction = createAction('[Login View Completed] log in completed');

export const LoggedInAction = createAction('[Logged In View] user is loggedin',
  props<{ payload: string }>());

export const IsLoggedInAction = createAction('[Is Logged in] check');

export const CheckAvailabilityRequestAction = createAction('[Login View] check availbility');

export const CheckAvailabilityResponseAction = createAction('[Login View] set check availbility',
  props<{ payload: OkModel<boolean> }>());
