import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { IEmployeeAddState } from './state';
import { RequestAction, ResetFormAction, ResponseAction, ResponseContributionRateAction, SubmitResponseAction } from './actions';
import { AddressModel, Helper } from '@ifaa-components/ui-components';
import { NewEmployeeModel } from 'src/app/model/employee.model';

export const formName = 'EmployeeAddForm';
var helper = new Helper();
export const state: IEmployeeAddState = {
  form: createFormGroupState(formName, new NewEmployeeModel()),
  lastModifiedTime: null,
  contributionRate: []
};

export const validateAddEmployeeForm = updateGroup<NewEmployeeModel>({
  firstName: validate(required),
  lastName: validate(required),
  email: validate(email),
  gender: validate(required),
  occupation: validate(required),
  dateOfBirth: validate(required),
  employmentStartDate: validate(required)
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: setValue(new NewEmployeeModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue(new NewEmployeeModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new NewEmployeeModel(), payload,
        {
          postalAddress: Object.assign(new AddressModel(), payload.postalAddress),
          residentialAddress: Object.assign(new AddressModel(), payload.residentialAddress)
        }
      ))(state.form),
    };
  }),
  on(ResponseContributionRateAction, (state, { payload }) => {
    return {
      ...state,
      contributionRate: payload
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const addEmployeeReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateAddEmployeeForm,
);

export function employeeAddReducer(state: any | undefined, action: Action) {
  return addEmployeeReducerFormState(state, action);
}
