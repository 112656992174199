import { FormGroupState } from "ngrx-forms";
import { EmployeeModel } from "src/app/model/employee.model";

export interface IEmployeeListViewState {
  list: EmployeeModel[];
  filter: FormGroupState<EmployeeListViewFilter>;
  isLoading: boolean;
}

export class EmployeeListViewFilter {
  q: string = '';
  currentPage: number = 0;
}
