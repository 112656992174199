<div id="employer-edit-container">
  <div class="card">
    <div *ngIf="data$ | async; let data">
      <div class="d-flex flex-wrap">
        <app-readonly title="Contribution Start Date" [value]="data.startDate | date:'dd/MM/yyyy' " icon="contact_page"
          class="w-50 pr-1">
        </app-readonly>
        <app-readonly title="Contribution End Date" [value]="data.endDate | date:'dd/MM/yyyy'" icon="contact_page"
          class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Severance Total" [value]="data.severanceTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Jetco Total" [value]="data.trainingTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Donation Total" [value]="data.donationTotal" icon="attach_money" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Contribution Total" [value]="data.contributionTotal" icon="attach_money"
          class="w-50 pl-1">
        </app-readonly>
        <div class="table-wrapper  w-100 d-flex flex-column">
          <div class="contribution">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Employee Code</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Severance Amount</th>
                  <th scope="col">Jetco Amount</th>
                  <th scope="col">Donation Amount</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data.contributionDetails" class="click">
                  <td>{{item.employeeCode}}</td>
                  <td>{{item.fullName}}</td>
                  <td>{{item.dateOfBirth | date:'dd/MM/yyyy'}}</td>
                  <td>
                    <div>{{item.severanceAmount}}</div>
                  </td>
                  <td>
                    <div>{{item.trainingAmount}}</div>
                  </td>
                  <td>
                    <div>{{item.donationAmount}}</div>
                  </td>
                  <td>{{calculateContributionTotal(item) | currency}}
                  </td>
                </tr>
              </tbody>
            </table>
    
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
