import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IEmployeeEditState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const editEmployee = createSelector(state, (state) => state.employeeEditState);

export const editEmployee_Form = createSelector(
  editEmployee,
  (state: IEmployeeEditState) => state.form
);

export const editEmployee_LastModified = createSelector(
  editEmployee,
  (state: IEmployeeEditState) => state.lastModifiedTime
);
