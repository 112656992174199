<div id="employer-edit-container">
  <div class="card">
    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="d-flex flex-wrap">
        <app-readonly title="Employer Name" [value]="form.value.name" class="w-50 p-1 flex-fill">
        </app-readonly>
        <app-readonly title="Employer Number" [value]="form.value.employerNumber" class="w-50 p-1 flex-fill">
        </app-readonly>
        <app-readonly title="Employer ABN" [value]="form.value.abn" class="w-100 p-1 flex-fill"></app-readonly>


        <app-entry title="Primary Contact First Name" [control]="form.controls.primaryContact.controls.firstName"
          icon="contact_page" class="w-50 p-1">
        </app-entry>

        <app-entry title="Primary Contact Last Name" [control]="form.controls.primaryContact.controls.lastName"
          icon="contact_page" class="w-50 p-1">
        </app-entry>

        <app-entry title="Email" [control]="form.controls.primaryContact.controls.email" icon="email" class="w-50 p-1">
        </app-entry>

        <app-entry title="Mobile" [control]="form.controls.primaryContact.controls.mobile" icon="phone"
          class="w-50 p-1">
        </app-entry>

        <app-entry title="Position" [control]="form.controls.primaryContact.controls.position" icon="badge"
          class="w-50 p-1">
        </app-entry>

        <app-entry-number title="Default Jetco Agreement" [control]="form.controls.defaultJetcoAgreement" icon="gavel"  [decimalplaces]="2"
          class="w-50 p-1">
        </app-entry-number>

        <app-entry-number title="Default Charity Donation" [control]="form.controls.defaultCharityDonation" [decimalplaces]="2"
          icon="volunteer_activism" class="w-50 p-1">
        </app-entry-number>

        <app-entry-address-accurity title="{{ 'POSTAL_ADDRESS' | translate }}" [address]="form.controls.postalAddress"
          class="w-100" [isSearchMode]="false" [allowOverseasAddress]="false">
        </app-entry-address-accurity>

        <entry-slide-toggle title="Is Business Address the same as the Postal Address?" class="w-100"
          [control]="form.controls.isPostalSameAsBusiness" iconTrue="" iconFalse="">
        </entry-slide-toggle>

        <app-entry-address-accurity title="{{ 'BUSINESS_ADDRESS' | translate }}" [allowOverseasAddress]="false"
          [address]="form.controls.businessAddress" *ngIf="!form.value.isPostalSameAsBusiness" class="w-100"
          [isSearchMode]="false">
        </app-entry-address-accurity>

      </div>
      <div class="components-actions">
        <app-button loading class="float-right w-25" title="Save Details" icon="save" type="submit" [form]="form"
          [isDisabled]="form.isInvalid" (onClick)="onSubmitClick(form.value)">
        </app-button>
      </div>

    </form>
  </div>
</div>
