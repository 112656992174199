import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

export class BaseService {
  constructor(public toastr: ToastrService) {
  }

  headers() {
    var token = window.localStorage.getItem('token_name');
    if (token) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      });
    }
    else {
      return new HttpHeaders({
      });
    }

  }

  multiPartheaders() {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + window.localStorage.getItem('token_name'),
    });
  }
  showError(errors) {
    const toastr = this.toastr;
    errors.forEach(function (element) {
      toastr.error(element);
    });
  }

  showOk(message) {
    if (message)
      this.toastr.success(message);
  }
}
