import { AddressModel } from '@ifaa-components/ui-components';
import { EmploymentModel } from './employment.model';
export class EmployeeModel {
  employeeId: number = 0;
  employeeNumber: string = '';
  gender: string = '';
  fullName: string = '';
  dateOfBirth: string = '';
  email: string = '';
  status: string = '';
  employment: EmploymentModel = new EmploymentModel();
  memberType: string = '';
  eba: string = '';
  mobile: string = '';
  homePhone: string = '';
  occupation: string = '';
  referenceNumber: string = '';
  residentialAddress: AddressModel = new AddressModel();
  postalAddress: AddressModel = new AddressModel();
  contributionBalance: number = 0;
  certificateOfEmploymentId: number = 0;
  isPostalSameAsResidential: boolean = false;
}

export class NewEmployeeModel {
  employerNewMemberRequestId: number = 0;
  membershipNumber: string = '';
  redundancyMember: boolean = false;
  firstName: string = '';
  mobile: string = '';
  gender: string = '';
  occupation: string = '';
  middleName: string = '';
  email: string = '';
  lastName: string = '';
  dateOfBirth: string = '';
  employmentStartDate: string = '';
  eba: string = '';
  residentialAddress: AddressModel = new AddressModel();
  postalAddress: AddressModel = new AddressModel();
  contributionRate: number = 0;

}
