import { AccountsService } from 'src/app/services/accounts.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { WebUserService } from 'src/app/services/webuser.service';

@Injectable()
export class ChangePasswordEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    public toastr: ToastrService,
    private router: Router,
    private service: WebUserService) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitAction),
    switchMap((action: any) => this.service.changepassword(action.payload)),
    map((data: any) => {
      this.router.navigate(['dashboard']);
      this.service.showOk(data.message);
      return ActionsList.SubmitCompletedAction();
    })
  ));
}
