import { processedContribution_Data } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ContributionDetailModel } from 'src/app/model/contribution.model';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-processed-contribution',
  templateUrl: './processed-contribution.component.html',
  styleUrls: ['./processed-contribution.component.scss']
})
export class ProcessedContributionComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(processedContribution_Data));
  constructor(public store: Store<EmployerPortalSharedState>,
    public appStore: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.getRouteParam(this.activatedRoute, 'contributionId', (id) => {
      this.dispatch(this.store, RequestAction({ id: id }));
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }

  calculateContributionTotal(data: ContributionDetailModel) {
    return data.severanceAmount + data.trainingAmount + data.donationAmount;
  }
}
