<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options  backlink="/contribution-list">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Upload File</div>
      </div>

    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-map-file-edit></app-map-file-edit>
      </div>
    </app-component-wrapper>


  </div>
</div>
