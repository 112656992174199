import { AppState } from 'src/app/store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployerViewOverviewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const employerViewOverview = createSelector(state, (state) => state.employerViewOverviewState);

export const employerViewOverview_Model = createSelector(
  employerViewOverview,
  (state: IEmployerViewOverviewState) => state.model
);
