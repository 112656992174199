import { FeatureFlagGuardService } from './route-guard/feature-flag-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { GetTenantDashboard, GetTenantMainLayout, GetTenantNotLoggedInLayout } from './tenant.helper';
import { AuthGuardService } from './route-guard/auth-guard.service';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { EmployerDetailsComponent } from './views/employer-details/employer-details.component';
import { EmployerEditComponent } from './views/employer-edit/employer-edit.component';
import { EmployeeDetailsComponent } from './views/employee-details/employee-details.component';
import { EmployerDetailsViewComponent } from './views/employee-view/employee-view.component';
import { EmployeeEditComponent } from './views/employee-edit/employee-edit.component';
import { ContributionListViewComponent } from './views/contribution-view/contribution-view.component';
import { ContributionEditComponent } from './views/contribution-edit/contribution-edit.component';
import { CertificateOfEmploymentEditComponent } from './views/certificate-of-employment-edit/certificate-of-employment-edit.component';
import { CertificateOfEmploymentViewComponent } from './views/certificate-of-employment-view/certificate-of-employment-view.component';
import { EmployeeAddComponent } from './views/employee-add/employee-add.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { NewEmployeeDetailsViewComponent } from './views/new-employee-view/new-employee-view.component';
import { MapFileEditViewComponent } from './views/mapfile-edit-view/mapfile-edit-view.component';
import { ProcessedContributionViewComponent } from './views/processed-contribution-view/processed-contribution-view.component';

var routes: Routes = [
  {
    path: 'login',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  },
  {
    path: 'reset-password/:token',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ResetPasswordComponent
      }
    ]
  },

  {
    path: 'dashboard',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: GetTenantDashboard.layout()
      }
    ]
  },
  {
    path: 'mapfile',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService, FeatureFlagGuardService],
    data: { flag: 'isUploadFileEnabled' },
    children: [
      {
        outlet: 'master',
        path: '',
        component: MapFileEditViewComponent
      }
    ]
  },
  {
    path: 'employer-details',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployerDetailsComponent
      }
    ]
  },
  {
    path: 'contribution-list',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionListViewComponent
      }
    ]
  },
  {
    path: 'add-contribution',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionEditComponent
      }
    ]
  },
  {
    path: 'view-contribution/:contributionId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionEditComponent
      }
    ]
  },
  {
    path: 'processed-contribution/:contributionId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ProcessedContributionViewComponent,
      }
    ]
  },
  {
    path: 'add-contribution/copy/:contributionId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionEditComponent
      }
    ]
  },
  {
    path: 'add-contribution/:contributionId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContributionEditComponent
      }
    ]
  },

  {
    path: 'certificate-of-employment-edit/:employmentId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: CertificateOfEmploymentEditComponent
      }
    ]
  },
  {
    path: 'certificate-of-employment-view/:certificateid',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: CertificateOfEmploymentViewComponent
      }
    ]
  },
  {
    path: 'employee-details',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployeeDetailsComponent
      }
    ]
  },
  {
    path: 'employee-details/:id/:employmentId',
    component: GetTenantMainLayout.mainLayout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployerDetailsViewComponent
      }
    ]
  },
  {
    path: 'employer-edit',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployerEditComponent
      }
    ]
  },
  {
    path: 'employee-add',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployeeAddComponent
      }
    ]
  },
  {
    path: 'employee-edit/:id/:employmentId',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmployeeEditComponent
      }
    ]
  },
  {
    path: 'new-employee-details/:id',
    component: GetTenantMainLayout.mainLayout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: NewEmployeeDetailsViewComponent
      }
    ]
  },
  {
    path: 'change-password',
    component: GetTenantMainLayout.mainLayout(),
    canActivate: [AuthGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ChangePasswordComponent
      }
    ]
  },

  {
    path: 'cirt',
    loadChildren: () => import('./modules/cirt/cirt-routing.module').then(m => m.CirtRoutingModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
