import { resetPassword_Form } from './selectors';
import { ResetPasswordModel } from './../../model/reset-password.model';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { ResetPasswordRequestAction, SetTokenAction } from './actions';
import { CheckAvailabilityRequestAction } from '../login/actions';
import { getLogIn_isUnavailable, getLogIn_isUnavailableMessage } from '../login/selectors';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(resetPassword_Form));
  isUnavailable$ = this.store.pipe(select(getLogIn_isUnavailable));
  isUnavailableMessage$ = this.store.pipe(select(getLogIn_isUnavailableMessage));

  constructor(public store: Store<AppState>,
    public activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, CheckAvailabilityRequestAction());
    this.getRouteParam(this.activatedRoute, 'token', (token) => {
      this.dispatch(this.store, SetTokenAction({ payload: token }))
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  submit(model: ResetPasswordModel) {
    this.dispatch(this.store, ResetPasswordRequestAction({ payload: model }));
  }
}
