import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { IDraftContributionViewState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const selector = createSelector(state, (state) => state.draftContributionsViewState);

export const draftContributionsView_List = createSelector(
  selector,
  (state: IDraftContributionViewState) => state.list
);

export const draftContributionsView_CurrentPage = createSelector(
  selector,
  (state: IDraftContributionViewState) => state.currentPage
);
