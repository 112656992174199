import { ContributionDetailModel, ContributionModel } from './../../../../model/contribution.model';
import { editContribution_ColumnSort, editContribution_Form, editContribution_IsCloneMode, editContribution_IsEditMode, editContribution_LastModified, editContribution_SubmitResponse, editContribution_TotalUpdate } from './selectors';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ColumnSortAction, RequestAction, RequestCloneAction, ResetFormAction, ResponseAction, SaveRequestAction, SubmitRequestAction, SumTotalsAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { debounceTime } from 'rxjs/operators';
import { commomState_IsLoading, commomState_YesNoList } from 'src/app/store/common/common.selectors';
import { LocationStrategy } from '@angular/common';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-contribution-edit',
  templateUrl: './contribution-edit.component.html',
  styleUrls: ['./contribution-edit.component.scss']
})
export class ContributionEditComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() isUploadFile: boolean = false;
  @Input() set setContributionData(data: ContributionModel) {
    if (data)
      this.dispatch(this.store, ResponseAction({ payload: data }));
    setTimeout(() => {
      this.store.dispatch(SumTotalsAction());
    }, 1000);

  }

  form$ = this.store.pipe(select(editContribution_Form));
  isEditMode$ = this.store.pipe(select(editContribution_IsEditMode));
  isCloneMode$ = this.store.pipe(select(editContribution_IsCloneMode));
  lastModified$ = this.store.pipe(select(editContribution_LastModified));
  totalUpdate$ = this.store.pipe(select(editContribution_TotalUpdate));
  submitResponse$ = this.store.pipe(select(editContribution_SubmitResponse));
  columnSort$ = this.store.pipe(select(editContribution_ColumnSort));
  yesNo$ = this.appStore.pipe(select(commomState_YesNoList));
  isLoading$ = this.appStore.pipe(select(commomState_IsLoading));
  sumLoaded: boolean = false;
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  contributionList: any[] = [];
  currentDisplayValid: any = null;
  rowHeight = "87";
  constructor(public store: Store<EmployerPortalSharedState>,
    public appStore: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private url: LocationStrategy,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.getRouteParam(this.activatedRoute, 'contributionId', (id) => {
      if (this.isUploadFile) {
        return;
      }
      var url = this.url.path();
      if (url.indexOf('copy') > 0) {
        this.dispatch(this.store, RequestCloneAction({ id: id }));
      }
      else {
        this.dispatch(this.store, RequestAction({ id: id }));
      }
    }, () => {
      if (this.isUploadFile) {
        return;
      }

      this.dispatch(this.store, RequestAction({ id: 0 }));
    });

    this.sub = this.form$.subscribe(x => {
      if (x && x.value.contributionDetails.length > 0) {
        if (!this.sumLoaded) {
          this.sumLoaded = true;
          this.store.dispatch(SumTotalsAction());
        }
        this.currentDisplayValid = x.value.displayValid;
        this.filterContributionDetails(this.currentDisplayValid, x.controls.contributionDetails, x.value.status);
      }
    });

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });

        this.dispatch(this.store, ResetFormAction());
      }
    });

    this.sub = this.totalUpdate$
      .pipe(
        debounceTime(500))
      .subscribe(totalUpdate => {
        if (totalUpdate) {
          this.store.dispatch(SumTotalsAction());
        }
      })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  trackByFn(index, item) {
    return index;
  }

  filterContributionDetails(displayValid: boolean, list: any, status: number) {
    if (status >= 2) {
      this.contributionList = list.controls;
      // this.rowHeight = "30";

      return list;
    }

    // this.rowHeight = "87";

    if (!displayValid) {
      this.contributionList = list.controls;
      return list;
    }

    var l = list.controls.filter(x => !x.value.endDate);
    this.contributionList = l;
    return l;
  }

  onSaveClick(data: ContributionModel) {
    this.dispatch(this.store, SaveRequestAction({ payload: data }));
  }

  onSubmitClick(confirmation:string, data: ContributionModel) {
    if (confirmation == "ok")
      this.dispatch(this.store, SubmitRequestAction({ payload: data }));
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }

  onColumnHeaderClick(column: string): void {
    this.dispatch(this.store, ColumnSortAction({ column: column, direction: '' }))
  }

  calculateContributionTotal(data: ContributionDetailModel) {
    return data.severanceAmount + data.trainingAmount + data.donationAmount;
  }
}
