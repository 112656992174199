import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue, validate
} from 'ngrx-forms';
import { FileMappingInfoModel, IMapFileEditState, MapFileModel } from './state';
import {
  FileContentChangedAction, NextStepAction, PreviousStepAction, RequestAction, ResetFormAction,
  ResponseAction, ResponseMapToOptionsAction, SubmitResponseAction, SubmitUploadFileResponseAction, ValidateUploadFileResponseAction
} from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { minLength, required } from 'ngrx-forms/validation';

export const formName = 'MapFileEditForm';
var helper = new Helper();

export const state: IMapFileEditState = {
  form: createFormGroupState(formName, new MapFileModel()),
  currentStep: 0,
  totalSteps: 3,
  validated: false,
  lastModifiedTime: null,
  mapToOptions: [],
  contribution: null,
  contributionCheck: null,
  totalColumn: 0,
  columns: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Z']
};

export const validateEditEmployeeForm = updateGroup<MapFileModel>({
  mapping: validate(required)
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      currentStep: 0,
      mapToOptions: [],
      contributionCheck: null,
      form: setValue(new MapFileModel())(state.form),
      lastModifiedTime: null,
      contribution: null,
      totalColumn: 0
    };
  }),
  on(ResponseMapToOptionsAction, (state, { data }) => {

    return {
      ...state,
      mapToOptions: data
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue(new MapFileModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(FileContentChangedAction, (state, { data }) => {

    if (!data) {
      return {
        ...state,
        totalColumn: 0,
        form: createFormGroupState(formName, new MapFileModel()),
      };
    }

    var clone = helper.clone(state.form.value);

    var count = 0;
    var index = 0;
    for (const line of data.split(/[\r\n]+/)) {
      if (count == 0) {
        var columns = line.split(',');
        columns.forEach(element => {
          var m = new FileMappingInfoModel();
          m.columnName = element;
          m.columnIndex = index;
          m.columnContent = [];
          clone.mapping.push(m);
          index++;
        });
      }
      else {
        var content = line.split(',');
        for (let index = 0; index < content.length; index++) {
          clone.mapping[index].columnContent.push(content[index]);
        }

      }
      count++;
    }

    var minColumns = clone.mapping.length;

    clone.mapping.forEach(element => {
      var mapto = state.mapToOptions.filter(x => x.key.toString().toLowerCase() == element.columnName.toLowerCase().replace(/\s/g, ''));
      if (mapto.length > 0) {
        element.mapTo = mapto[0].key.toString();
      }
    });


    while (clone.mapping.length < minColumns) {
      clone.mapping.push(new FileMappingInfoModel());
    }

    return {
      ...state,
      totalColumn: minColumns,
      form: createFormGroupState(formName, clone)
    };

  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new MapFileModel(), payload,
        {

        }
      ))(state.form),
    };
  }),
  on(NextStepAction, (state) => {

    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false
    };
  }),
  on(ValidateUploadFileResponseAction, (state, { data }) => {
    var currentStep = state.currentStep;
    // if (data.errorList.length == 0) {
    //   currentStep += 2;
    // }
    // else {
    currentStep += 1;

    // }
    return {
      ...state,
      contributionCheck: data,
      currentStep: currentStep,
      validated: false
    };
  }),
  on(SubmitUploadFileResponseAction, (state, { data }) => {
    var currentStep = state.currentStep;
    currentStep += 1;

    return {
      ...state,
      contribution: data,
      currentStep: currentStep,
      validated: false
    };
  }),
  on(PreviousStepAction, (state) => {
    var clone = helper.clone(state.form.value)

    return {
      ...state,
      currentStep: state.currentStep - 1,
      validated: false,
      form: createFormGroupState(formName, clone)
    };
  }),
  on(SubmitResponseAction, (state, { data }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const editEmployeeReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditEmployeeForm,
);

export function mapfileEditReducer(state: any | undefined, action: Action) {
  return editEmployeeReducerFormState(state, action);
}
