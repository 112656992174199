import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IEmployerEditState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const editEmployer = createSelector(state, (state) => state.employerEditState);

export const editEmployer_Form = createSelector(
  editEmployer,
  (state: IEmployerEditState) => state.form
);

export const editEmployer_LastModified = createSelector(
  editEmployer,
  (state: IEmployerEditState) => state.lastModifiedTime
);
