import { AppState } from '../../../../store/app.states';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { LoadMoreAction, RequestAction, ResetPageAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { processedContributionsView_CurrentPage, processedContributionsView_List } from './selectors';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-processed-contributions-list',
  templateUrl: './processed-contributions-list.component.html',
  styleUrls: ['./processed-contributions-list.component.scss']
})
export class ProcessedContributionsListViewComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(processedContributionsView_List));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  currentPage$ = this.appstore.pipe(select(processedContributionsView_CurrentPage));

  @Input() fullpage: boolean = true;

  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(ResetPageAction());
    this.currentPage$
      .pipe(
        distinctUntilChanged((prev, curr) => prev === curr))
      .subscribe(page => {

        if (page != -1) {

          this.store.dispatch(RequestAction({ page }));
        }
      })
  }

  endOfPageEvent() {
    if (!this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  endOfPageFullEvent() {
    if (this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
