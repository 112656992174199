import { AppState } from '../../../../store/app.states';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { LoadMoreAction, RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { previousContributionsView_CurrentPage, previousContributionsView_List } from './selectors';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-submitted-contributions',
  templateUrl: './submitted-contributions.component.html',
  styleUrls: ['./submitted-contributions.component.scss']
})
export class SubmittedContributionsViewComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(previousContributionsView_List));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  currentPage$ = this.appstore.pipe(select(previousContributionsView_CurrentPage));

  @Input() fullpage: boolean = true;

  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.currentPage$
      .pipe(
        distinctUntilChanged((prev, curr) => prev === curr))
      .subscribe(page => {
        if (page != -1)
          this.store.dispatch(RequestAction({ page }));
      })

    this.screenWidthChange(this.appstore, x => { });

  }

  endOfPageEvent() {
    if (!this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  endOfPageFullEvent() {
    if (this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  personalDetails() {
    this.router.navigate(['personal-details']);
  }

  changePassword() {
    this.router.navigate(['change-password']);
  }
}
