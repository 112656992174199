export class ContributionModel {
  contributionId: number = 0;
  severanceTotal: number = 0;
  trainingTotal: number = 0;
  donationTotal: number = 0;
  contributionTotal: number = 0;
  startDate: string = '';
  contributionDetails: ContributionDetailModel[] = [];
  status: number = 0;

  employeesRemoved: number = 0;
  employeesNew: number = 0;
  youAcknowledge: boolean = false;
  isClone: boolean = false;
  displayValid: boolean = true;

}
export class FileUploadContributionModel extends ContributionModel {
  errorList: any[] = [];
}
export class ContributionDetailModel {
  contributionDetailId: number = 0;
  employeeId: number = 0;
  employeeCode: string = '';
  reference: string = '';
  fullName: string = '';
  dateOfBirth: string = '';
  startDate: string = '';
  endDate: string = '';
  weeks: number = 0;
  severanceAmount: number = 0;
  trainingAmount: number = 0;
  donationAmount: number = 0;
  contributionTotal: number = 0;
  finalContribution: boolean;
  isNewMember: boolean;
  lineNo: number = 0;
  genderId: number = -1;
}
