import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { INewEmployeeViewState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const getNewEmployeeView = createSelector(state, (state) => state.newEmployeeViewState);

export const getNewEmployeeView_Model = createSelector(
  getNewEmployeeView,
  (state: INewEmployeeViewState) => state.model
);
