<div id="forgot-password-container" class="d-flex ">
  <div class="d-flex w-100">
    <div class="left-side">
      <!-- <img src="{{'fundLogo' | tenantconfig | async }}" style="height: 70px;width: auto; margin: 20px;"> -->
      <div class="left-side-content"></div>


    </div>
    <div class="right-side flex-fill d-flex align-items-center">
      <form [ngrxFormState]="form" (ngSubmit)="onSubmit(form.value)" *ngIf="form$ | async; let form" autocomplete="off"
        class=" d-flex w-100 justify-content-center" role="presentation">
        <div class="backdrop"></div>

        <div appanimatecss class="wrapper">
          <div class="p-3">
            <a target="_blank" href="{{'website' | tenantconfig | async }}"><img
              src="{{'fundLogo' | tenantconfig | async }}" class="logo" style="height: 70px;width: auto;"></a>
            <div class="page-title">

            </div>
            <div class=" d-flex flex-row">
              <div class="flex-fill d-flex flex-column">

                <h1 class="card-title mb-1 ">Forgot password</h1>
                <div class="card-body flex-fill d-flex align-items-center">

                  <div class="main-container w-100">
                    <div class="content animated fadeInUpSmall">
                      <div class="row">
                        <div class="col col-md-12">
                          <app-entry class="entry-dark-bg" [title]="'forgotPasswordScreen_UsernameTitle'|message|async" autofill="true"
                            [control]="form.controls.username" icon="account_box">
                          </app-entry>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <a class="forgot d-block mb-4" routerLink="/login">{{"LOGIN" |
                            translate}}</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-bottom">
                  <app-button loading class="w-100" type="submit" title="{{ 'SUBMIT' | translate }}" icon="east"
                    [isDisabled]="form.isInvalid" (onClick)="onSubmit(form.value)">
                  </app-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>


    </div>
  </div>
