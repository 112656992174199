import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployeeListViewState } from './state';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const selector = createSelector(state, (state) => state.employeeListView);

export const employeeListView_List = createSelector(
  selector,
  (state: IEmployeeListViewState) => state.list
);

export const employeeListView_isLoading = createSelector(
  selector,
  (state: IEmployeeListViewState) => state.isLoading
);

export const employeeListView_Filter = createSelector(
  selector,
  (state: IEmployeeListViewState) => state.filter
);
