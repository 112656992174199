<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options backlink="">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Employee Edit</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-employee-edit></app-employee-edit>
      </div>
    </app-component-wrapper>


  </div>
</div>
