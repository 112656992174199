import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { WebUserService } from 'src/app/services/webuser.service';
import { Router } from '@angular/router';
import { IsLoggedInRequestAction } from 'src/app/store/common/common.actions';

@Injectable()
export class ResetPasswordEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private webUserService: WebUserService,
  ) {
  }

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ResetPasswordRequestAction),
    switchMap((action: any) => this.webUserService.resetpassword(action.payload)),
    map((data: any) => {
      window.localStorage.setItem('token_name', data.data);
      this.router.navigate(['dashboard']);
      this.store.dispatch(IsLoggedInRequestAction());
      return ActionsList.ResetPasswordResponseAction();
    })
  ));
}
