import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-employee-add-view',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class EmployeeAddComponent extends ComponentBase implements OnInit, OnDestroy {
  employeeId = 0;
  constructor(public store: Store<AppState>,
    private wowService: NgwWowService,
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.wowService.init();
    });

  }

  ngOnInit() {
    super.ngOnInitBase();

    this.screenWidthChange(this.store, (width: number) => {


    });

    this.getRouteParam(this.activatedRoute, 'id', (id) => {
      this.employeeId = id;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
