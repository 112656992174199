import { ContributionModel } from 'src/app/model/contribution.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { FileMappingInfoModel, MapFileModel } from 'src/app/modules/shared/components/map-file-edit/state';
import { number } from 'ngrx-forms/validation';

@Injectable()
export class EPContributionService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getRates(): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions`, { headers: this.headers() });
  }

  getContribution(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions/${id}`, { headers: this.headers() });
  }

  maptoOptions(): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions/MapToOptions`, { headers: this.headers() });
  }

  getCloneContribution(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions/clone/${id}`, { headers: this.headers() });
  }

  getProcessedContribution(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions/processed/${id}`, { headers: this.headers() });
  }

  getTemplate(): Observable<any> {
    return this.http.get(environment.apiUrl + `/employer-portal/contributions/template/`, { headers: this.headers() });
  }

  save(model: ContributionModel): Observable<any> {
    return this.http.put(environment.apiUrl + `/employer-portal/contributions/save`, model, { headers: this.headers() });
  }

  submit(model: ContributionModel): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/contributions/submit`, model, { headers: this.headers() });
  }

  validateUploadFileRequest(model: FileMappingInfoModel[]): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/contributions/upload-file-validate-request`, model, { headers: this.headers() });
  }

  submitUploadFileRequest(model: FileMappingInfoModel[]): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/contributions/upload-file-submit-request`, model, { headers: this.headers() });
  }

  uploadfilesubmit(model: MapFileModel): Observable<any> {
    return this.http.post(environment.apiUrl + `/employer-portal/contributions/upload-file-submit`, model, { headers: this.headers() });
  }

  deleteDraft(id: number): Observable<any> {
    return this.http.delete(environment.apiUrl + `/employer-portal/contributions/draft/delete/${id}`, { headers: this.headers() });
  }
}
