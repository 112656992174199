import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const employerPortalSharedRoutes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(employerPortalSharedRoutes)],
  exports: [RouterModule]
})
export class EmployerPortalSharedRoutingModule {}
