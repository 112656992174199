import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { EmployeeModel } from 'src/app/model/employee.model';

export const ResetFormAction = createAction('[Edit Employee View] reset form');

export const RequestAction = createAction('[Edit Employee View] request data by Id', props<{ id: number, employmentId: number }>());

export const ResponseAction = createAction('[Edit Employee View] response data by Id',
  props<{ payload: EmployeeModel }>());

export const SubmitRequestAction = createAction('[Edit Employee View] edit Employee details',
  props<{ payload: EmployeeModel }>());

export const SubmitResponseAction = createAction('[Edit Employee View]  Employee update completed',
  props<{ payload: OkModel<string> }>());
