import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ResetPasswordModel } from 'src/app/model/reset-password.model';

export const ResetPasswordRequestAction = createAction('[Reset Password] reset password',
  props<{ payload: ResetPasswordModel }>());

export const ResetPasswordResponseAction = createAction('[Reset Password]set password reset');

export const SetTokenAction = createAction('[Reset Password] set token',
  props<{ payload: string }>());

