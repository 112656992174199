<app-component-wrapper class="w-100 mr-3 flex-fill" compclass="flex-fill ">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="employer-view-overview-container" class="card">
      <div class="d-flex flex-column" *ngIf="data$ | async; let data">
        <div class="header">
          <h4 class="card-title"> Welcome back!</h4>



        </div>
        <div class="content">
          <div class="d-flex flex-row flex-wrap">
            <app-readonly title="Employer Name" [value]="data.name" class="{{itemclass}} p-1 custom "></app-readonly>
            <app-readonly title="Employer Number" [value]="data.employerNumber" class="{{itemclass}} p-1 custom "></app-readonly>
            <app-readonly title="Email Address" [value]="data.primaryContact.email" class="{{itemclass}} p-1 custom"></app-readonly>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="d-flex flex-row-reverse page-content-actions text-right" slot="actions">
    <app-button icon="edit" class="" title="Edit Contact Details" (onClick)="personalDetails()"></app-button>

  </div>

