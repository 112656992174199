import { EmployeeModel } from 'src/app/model/employee.model';
import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';


export const ResetFormAction = createAction('[view certificate-of-employment] reset form');

export const RequestAction = createAction('[view certificate-of-employment] request data by Id', props<{ id: number }>());

export const ResponseAction = createAction('[view certificate-of-employment] response data by Id',
  props<{ payload: CertificateOfEmploymentModel }>());



export const RequestEmployeeAction = createAction('[view certificate-of-employment] request employee by Id', props<{ id: number }>());

export const ResponseEmployeeAction = createAction('[view certificate-of-employment] response employee by Id',
  props<{ payload: EmployeeModel }>());
