import { EPEmployerService } from '../../../../services/EmployerPortalServices/ep-employer.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { EPContributionService } from 'src/app/services/EmployerPortalServices/ep-contribution.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DraftContributionsViewEffects {
  constructor(private actions$: Actions,
    public toastr: ToastrService,
    private service: EPEmployerService,
    private serviceContribution: EPContributionService,
  ) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getDraftContributions(action.page)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  deleteDraft$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestDeleteAction),
    switchMap((action: any) => this.serviceContribution.deleteDraft(action.id)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.RequestAction({ page: 0 });
    })
  ));
}
