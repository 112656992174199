import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IEmployeeAddState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const addEmployee = createSelector(state, (state) => state.employeeAddState);

export const addEmployee_Form = createSelector(
  addEmployee,
  (state: IEmployeeAddState) => state.form
);

export const addEmployee_LastModified = createSelector(
  addEmployee,
  (state: IEmployeeAddState) => state.lastModifiedTime
);

export const addEmployee_contributionRate = createSelector(
  addEmployee,
  (state: IEmployeeAddState) => state.contributionRate
);
