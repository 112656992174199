import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EPCertificateService } from 'src/app/services/EmployerPortalServices/ep-certificate.service';

@Injectable()
export class EditCertificateOfEmploymentEffects {
  constructor(private actions$: Actions,
    public toastr: ToastrService,
    private certService: EPCertificateService
    ) {
  }

  getById$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.certService.getNewEmployeeCertificate(action.id)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));

  submit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SubmitRequestAction),
    switchMap((action: any) => this.certService.submit(action.payload)),
    map((data: any) => {
      this.toastr.success(data.message);
      return ActionsList.SubmitResponseAction({ payload: data });
    })
  ));
}
