import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { ICertificateOfEmploymentEditState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const editCertificateOfEmployment = createSelector(state, (state) => state.certificateOfEmploymentEditState);

export const editCertificateOfEmployment_Form = createSelector(
  editCertificateOfEmployment,
  (state: ICertificateOfEmploymentEditState) => state.form
);

export const editCertificateOfEmployment_LastModified = createSelector(
  editCertificateOfEmployment,
  (state: ICertificateOfEmploymentEditState) => state.lastModifiedTime
);

export const editCertificateOfEmployment_Validated = createSelector(
  editCertificateOfEmployment,
  (state: ICertificateOfEmploymentEditState) => state.validated
);

export const editCertificateOfEmployment_Employee = createSelector(
  editCertificateOfEmployment,
  (state: ICertificateOfEmploymentEditState) => state.form.value.employeeFullName
);
