import { PreviousContributionModel } from 'src/app/model/previous-contribution.model';
import { AppState } from '../../../../store/app.states';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { LoadMoreAction, RequestAction, RequestDeleteAction, ResetPageAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { draftContributionsView_CurrentPage, draftContributionsView_List } from './selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-draft-contributions',
  templateUrl: './draft-contributions.component.html',
  styleUrls: ['./draft-contributions.component.scss']
})
export class DraftContributionsViewComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(draftContributionsView_List));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  currentPage$ = this.appstore.pipe(select(draftContributionsView_CurrentPage));

  @Input() fullpage: boolean = true;

  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appstore: Store<AppState>,
    public dialog: MatDialog,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(ResetPageAction());
    this.currentPage$
      .pipe(
        distinctUntilChanged((prev, curr) => prev === curr))
      .subscribe(page => {

        if (page != -1) {

          this.store.dispatch(RequestAction({ page }));
        }
      })

    this.screenWidthChange(this.appstore, x => { });

  }

  endOfPageEvent() {
    if (!this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  endOfPageFullEvent() {
    if (this.fullpage)
      this.store.dispatch(LoadMoreAction())
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  personalDetails() {
    this.router.navigate(['personal-details']);
  }

  changePassword() {
    this.router.navigate(['change-password']);
  }
  onDeleteDraftContributionClick(model: PreviousContributionModel) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      data: 'Do you really want to continue?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dispatch(this.store, RequestDeleteAction({ id: model.employerContributionHeaderId }));
      }
    });
  }
}
