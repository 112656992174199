export class CertificateOfEmploymentModel {
  certificateOfEmploymentId: number = 0;
  employmentId: number = 0;
  employeeId: number = 0;
  employeeNumber: string = '';
  employeeFullName: string = '';
  dateOfBirth: string = '';
  employerName: string = '';
  employmentStartDate: string = '';
  employmentEndDate: string = '';
  agreeToCertify: boolean = false;
  certifyNoDetails: string = '';
  employeeAnnualSalary: number = 0;
  unpaidAbsenceInWeeks: number = 0;
  numberOfWeeksRedundancyCalculation: number = 0;
  totalRedundancyPayment: number = 0;
  numberOfWeeksPayment: number = 0;
  totalAmountDue: number = 0;
  totalPaymentByEmployer: number = 0;
}
