import { Directive, ElementRef, Renderer2, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[infinitescroll]',
})
export class InfiniteScrollDirective {

  @Output('event') event: EventEmitter<any> = new EventEmitter();
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
  //In chrome and some browser scroll is given to body tag
  let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  let max = document.documentElement.scrollHeight;
  // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
   if(Math.ceil(pos) == Math.ceil(max) )   {
    this.event.emit();
   }
  }

  constructor(public renderer: Renderer2, public hostElement: ElementRef) {
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {
  }

}
