<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options backlink="/employee-details">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">New Employee Request Details</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-new-employee-view></app-new-employee-view>
      </div>
    </app-component-wrapper>
  </div>
</div>
