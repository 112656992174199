import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const smrtRoutes: Routes = [
];

@NgModule({
  imports: [RouterModule.forChild(smrtRoutes)],
  exports: [RouterModule]
})
export class SMRTRoutingModule { }
