import { featureKey } from '../../store/cirt.reducers';
import { CirtState } from '../../store/cirt.states';
import { AppState } from './../../../../store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMainLayoutState } from './state';

export const memberPortalState = createFeatureSelector<CirtState>(featureKey);

export const mainLayout = createSelector(memberPortalState, (state) => state.mainLayout);

export const mainLayout_Animated = createSelector(
  mainLayout,
  (state: IMainLayoutState) => state.animated
);

export const mainLayout_Employer = createSelector(
  mainLayout,
  (state: IMainLayoutState) => state.employer
);


