import { InputScrollDirective, InputAddressScrollDirective, InputDateScrollDirective } from './directives/input-scroll.directive';
import { ScreenWidthDirective } from './directives/screen-width.directive';
import { MessagePipe } from './pipes/message';
import { AppRadioDirective } from './directives/app-radio.directive';
import { AppButtonDirective } from './directives/appbutton.directive';
import { MaterialModule } from './../../app.material.module';
import { AppComponentWrapperComponent } from './components/app-component-wrapper/app-component-wrapper.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TenantConfigPipe } from './pipes/tenantconfig';
import { AnimateCssDirective } from './directives/animatecss.directive';
import { FadeInConcatDirective } from './directives/fadein-concat.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { MessageDirective } from './directives/message.directive';
import { PermissionDirective } from './directives/permission.directive';
import { NgrxFormsModule } from 'ngrx-forms';
import { featureKey, reducers } from './store/shared.reducers';
import { EmployerPortalSharedRoutingModule } from './shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EmployerViewOverviewComponent } from './components/employer-view-overview/employer-view-overview.component';
import { EmployerViewOverviewEffects } from './components/employer-view-overview/effects';
import { SubmittedContributionsViewComponent } from './components/submitted-contributions/submitted-contributions.component';
import { PreviousContributionsViewEffects } from './components/submitted-contributions/effects';
import { EmployeeListViewComponent } from './components/employee-list-view/employee-list-view.component';
import { EmployeeListViewEffects } from './components/employee-list-view/effects';
import { EmployerViewEffects } from './components/employer-view/effects';
import { EmployerViewComponent } from './components/employer-view/employer-view.component';
import { EditEmployerEffects } from './components/employer-edit/effects';
import { EmployerEditComponent } from './components/employer-edit/employer-edit.component';
import { EmployeeViewComponent } from './components/employee-view/employee-view.component';
import { EmployeeViewEffects } from './components/employee-view/effects';
import { EditEmployeeEffects } from './components/employee-edit/effects';
import { EmployeeEditComponent } from './components/employee-edit/employee-edit.component';
import { ContributionEditComponent } from './components/contribution-edit/contribution-edit.component';
import { EditContributionEffects } from './components/contribution-edit/effects';
import { CertificateOfEmploymentEditComponent } from './components/certificate-of-employment-edit/certificate-of-employment-edit.component';
import { CertificateOfEmploymentViewComponent } from './components/certificate-of-employment-view/certificate-of-employment-view.component';
import { EditCertificateOfEmploymentEffects } from './components/certificate-of-employment-edit/effects';
import { InfiniteScrollDirective } from './directives/infinitescroll.directive';
import { ViewCertificateOfEmploymentEffects } from './components/certificate-of-employment-view/effects';
import { AddEmployeeEffects } from './components/employee-add/effects';
import { EmployeeAddComponent } from './components/employee-add/employee-add.component';
import { DraftContributionsViewEffects } from './components/draft-contributions/effects';
import { DraftContributionsViewComponent } from './components/draft-contributions/draft-contributions.component';
import { NewEmployeeRequestListViewEffects } from './components/new-employee-request-list-view/effects';
import { NewEmployeeRequestListViewComponent } from './components/new-employee-request-list-view/new-employee-list-view.component';
import { NewEmployeeViewComponent } from './components/new-employee-view/new-employee-view.component';
import { NewEmployeeViewEffects } from './components/new-employee-view/effects';
import { MapFileEditEffects } from './components/map-file-edit/effects';
import { MapFileEditComponent } from './components/map-file-edit/map-file-edit.component';
import { ProcessedContributionsViewEffects } from './components/processed-contributions-list/effects';
import { ProcessedContributionsListViewComponent } from './components/processed-contributions-list/processed-contributions-list.component';
import { ProcessedContributionComponent } from './components/processed-contribution/processed-contribution.component';
import { ProcessedContributionEffects } from './components/processed-contribution/effects';
import { InfiniteScrollCompDirective } from './directives/infinitescroll-component.directive';
import { JoyrideModule } from 'ngx-joyride';
import { AppTooltipComponent } from './components/app-tooltip/app-tooltip.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    TenantConfigPipe,
    MessagePipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    MessageDirective,
    ScreenWidthDirective,
    PermissionDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InputDateScrollDirective,
    InfiniteScrollDirective,
    InfiniteScrollCompDirective,
    AppComponentWrapperComponent,
    EmployerViewOverviewComponent,
    SubmittedContributionsViewComponent,
    ProcessedContributionsListViewComponent,
    DraftContributionsViewComponent,
    EmployeeListViewComponent,
    NewEmployeeRequestListViewComponent,
    EmployerViewComponent,
    EmployerEditComponent,
    EmployeeAddComponent,
    EmployeeViewComponent,
    NewEmployeeViewComponent,
    EmployeeEditComponent,
    ContributionEditComponent,
    ProcessedContributionComponent,
    CertificateOfEmploymentEditComponent,
    CertificateOfEmploymentViewComponent,
    MapFileEditComponent,
    AppTooltipComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    NgApexchartsModule,
    HttpClientModule,
    ScrollingModule,
    JoyrideModule.forRoot(),
    TooltipModule ,
    IfaaUiComponentsModule,
    EmployerPortalSharedRoutingModule,
    MaterialModule,
    NgrxFormsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([
      EmployerViewOverviewEffects,
      PreviousContributionsViewEffects,
      ProcessedContributionEffects,
      ProcessedContributionsViewEffects,
      DraftContributionsViewEffects,
      EmployeeListViewEffects,
      NewEmployeeRequestListViewEffects,
      EmployerViewEffects,
      EditEmployerEffects,
      EmployeeViewEffects,
      EditEmployeeEffects,
      AddEmployeeEffects,
      NewEmployeeViewEffects,
      EditContributionEffects,
      EditCertificateOfEmploymentEffects,
      ViewCertificateOfEmploymentEffects,
      MapFileEditEffects
    ]),
    TranslateModule.forChild(),
  ],
  exports: [
    TenantConfigPipe,
    MessagePipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    InfiniteScrollDirective,
    MessageDirective,
    PermissionDirective,
    ScreenWidthDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InfiniteScrollCompDirective,
    InputDateScrollDirective,
    AppComponentWrapperComponent,
    EmployerViewOverviewComponent,
    SubmittedContributionsViewComponent,
    ProcessedContributionsListViewComponent,
    DraftContributionsViewComponent,
    EmployeeListViewComponent,
    NewEmployeeRequestListViewComponent,
    EmployerViewComponent,
    EmployerEditComponent,
    EmployeeViewComponent,
    EmployeeEditComponent,
    EmployeeAddComponent,
    NewEmployeeViewComponent,
    ContributionEditComponent,
    ProcessedContributionComponent,
    CertificateOfEmploymentEditComponent,
    CertificateOfEmploymentViewComponent,
    MapFileEditComponent,
    AppTooltipComponent
  ],
  providers: [
  ],
  entryComponents: [
  ]
})
export class EmployerPortalSharedModule { }
