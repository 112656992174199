<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="paid">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Contributions</div>
      </div>
      <div class="d-flex flex-row align-items-center" slot="end">
        <app-tooltip [text]="'employerPortal_Add_Contribution'|message|async"></app-tooltip>
        <app-button class="ml-2 float-right mr-1" title="Add Contribution" icon="add" (onClick)="onAddContributionClick()"></app-button>
        <app-button featuretoggle="isUploadFileEnabled" class=" float-right" title="Upload File" icon="upload_file" (onClick)="onUploadFileClick()"></app-button>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="Draft Contributions">
        <div class="d-flex flex-column" slot="start">
          <app-draft-contributions></app-draft-contributions>
        </div>
      </app-component-wrapper>
    </div>
    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="Submitted Contributions">
        <div class="d-flex flex-column" slot="start">
          <app-submitted-contributions></app-submitted-contributions>
        </div>
      </app-component-wrapper>
    </div>
    <div class="d-flex flex-row mt-3">
      <app-component-wrapper class="mt w-100" icon="" title="Processed Contributions">
        <div class="d-flex flex-column" slot="start">
          <app-processed-contributions-list></app-processed-contributions-list>
        </div>
      </app-component-wrapper>
    </div>
    <div class="d-flex flex-row mt-3">
    </div>
  </div>
</div>
