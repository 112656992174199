<app-component-wrapper class="w-100 mr-3 flex-fill" compclass="flex-fill " infinitescroll
  (event)="endOfPageFullEvent()">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="draft-contributions-overview-container" class="card" endofpage (event)="endOfPageEvent()"
      [class.compact]="fullpage == false">
      <app-no-items message="No draft contribution found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
      <div class="table-wrapper">
        <table class="table " *ngIf="(list$ |async)?.length > 0">
          <thead>
            <tr>
              <th scope="col">Contribution Description</th>
              <th scope="col">Amount</th>
              <th scope="col">Number of Employees</th>
              <th scope="col">Reference</th>
              <th scope="col" style="width: 80px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list$ | async" class="click"
              routerLink="/view-contribution/{{item.employerContributionHeaderId}}">
              <td class="pt-4">{{item.description}}</td>
              <td class="pt-4">{{item.amount | currency}}</td>
              <td class="pt-4">{{item.numberOfEmployees}}</td>
              <td class="pt-4">{{item.reference }}</td>
              <td class="d-flex flex-row " (click)="$event.stopPropagation();">
                <app-button title="View" class="mr-1" icon="chevron_right"
                  routerLink="/view-contribution/{{item.employerContributionHeaderId}}">
                </app-button>

                <app-button title="Delete" color="danger" icon="close" (onClick)="onDeleteDraftContributionClick(item)">
                </app-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-component-wrapper>
