import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { certificateOfEmploymentEditReducer } from '../components/certificate-of-employment-edit/reducer';
import { certificateOfEmploymentViewReducer } from '../components/certificate-of-employment-view/reducer';
import { contributionEditReducer } from '../components/contribution-edit/reducer';
import { draftContributionsViewReducer } from '../components/draft-contributions/reducer';
import { employeeAddReducer } from '../components/employee-add/reducer';
import { employeeEditReducer } from '../components/employee-edit/reducer';
import { employeeListViewReducer } from '../components/employee-list-view/reducer';
import { employeeViewReducer } from '../components/employee-view/reducer';
import { employerEditReducer } from '../components/employer-edit/reducer';
import { employerViewOverviewReducer } from '../components/employer-view-overview/reducer';
import { employerViewReducer } from '../components/employer-view/reducer';
import { mapfileEditReducer } from '../components/map-file-edit/reducer';
import { newEmployeeRequestListViewReducer } from '../components/new-employee-request-list-view/reducer';
import { newEmployeeViewReducer } from '../components/new-employee-view/reducer';
import { processedContributionReducer } from '../components/processed-contribution/reducer';
import { processedContributionsViewReducer } from '../components/processed-contributions-list/reducer';
import { submittedContributionsViewReducer } from '../components/submitted-contributions/reducer';
import { EmployerPortalSharedState } from './shared.states';

export const featureKey = 'employer-portal-shared';

export const reducers = new InjectionToken<ActionReducerMap<EmployerPortalSharedState>>(featureKey, {
  factory: () => ({
    employerViewOverviewState: employerViewOverviewReducer,
    submittedContributionsViewState: submittedContributionsViewReducer,
    processedContributionsState: processedContributionReducer,
    processedContributionsListViewState: processedContributionsViewReducer,
    draftContributionsViewState: draftContributionsViewReducer,
    employeeListView: employeeListViewReducer,
    newEmployeeRequestListView: newEmployeeRequestListViewReducer,
    employerViewState: employerViewReducer,
    newEmployeeViewState: newEmployeeViewReducer,
    employeeViewState: employeeViewReducer,
    employerEditState: employerEditReducer,
    employeeEditState: employeeEditReducer,
    employeeAddState: employeeAddReducer,
    contributionEditState: contributionEditReducer,
    certificateOfEmploymentEditState: certificateOfEmploymentEditReducer,
    certificateOfEmploymentViewState: certificateOfEmploymentViewReducer,
    mapfileEditState: mapfileEditReducer
  })
});
