<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options backlink="">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Employee Details</div>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-employee-view></app-employee-view>
      </div>
      <div class="m-3 components-actions d-flex flex-row-reverse" slot="actions">
        <app-button class=" float-right w-25" title="Edit Employee" icon="edit" (onClick)="onEmployeeEditClick()">
        </app-button>
        <div *ngIf="(data$ | async) as data"
          [ngClass]="data.certificateOfEmploymentId && data.employment.endDate ? 'mr-2' : ' mr-2'">
          <div *ngIf="data.certificateOfEmploymentId && data.employment.endDate; then t else e"></div>
          <ng-template #t>
            <div class="d-flex flex-row align-items-center">
              <app-tooltip class="mr-2" [text]="'employerPortal_Certificate_Employment'|message|async"></app-tooltip>
              <app-button title="View Certificate of Employment" icon="badge"
                routerLink="/certificate-of-employment-view/{{data.certificateOfEmploymentId}}">
              </app-button>
            </div>
          </ng-template>
          <ng-template #e>
            <div class="d-flex flex-row align-items-center">
              <app-tooltip class="mr-2" [text]="'employerPortal_Certificate_Employment'|message|async"></app-tooltip>

              <app-button title="Certificate of Employment" icon="badge"
                routerLink="/certificate-of-employment-edit/{{data.employment.id}}">
              </app-button>
            </div>
          </ng-template>
        </div>
      </div>
    </app-component-wrapper>
  </div>
</div>
