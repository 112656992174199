import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { PreviousContributionModel } from 'src/app/model/previous-contribution.model';

export const RequestAction = createAction('[Draft Contributions view overview] get data', props<{ page: number }>());
export const ResponseAction = createAction('[Draft Contributions view overview] set data', props<{ payload: PreviousContributionModel[] }>());
export const LoadMoreAction = createAction('[Draft Contributions view overview] load more');
export const ResetPageAction = createAction('[Draft Contributions view overview] reset');
export const RequestDeleteAction = createAction('[Draft Contributions view overview]  delete request', props<{ id: number }>());
export const ResponseDeleteAction = createAction('[Draft Contributions view overview] delete response');

