<app-component-wrapper class="w-100 mr-3 flex-fill" compclass="flex-fill " infinitescroll
  (event)="endOfPageFullEvent()">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="processed-contributions-overview-container" class="card" endofpage (event)="endOfPageEvent()"
      [class.compact]="fullpage == false">
      <app-no-items message="No processed contribution found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
      <div class="table-wrapper">
        <table class="table " *ngIf="(list$ |async)?.length > 0">
          <thead>
            <tr>
              <th scope="col">Contribution Description</th>
              <th scope="col">Amount</th>
              <th scope="col">Number of Employees</th>
              <th scope="col">Reference</th>
              <th style="width: 120px;position: relative" >
                <app-tooltip style="position: absolute;right: 45px;top: 7px;"
                  [text]="'employerPortal_Processed_Contributions_Copy'|message|async"></app-tooltip>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list$ | async" class="click"
              routerLink="/processed-contribution/{{item.employerContributionHeaderId}}">
              <td>{{item.description}}</td>
              <td>{{item.amount | currency}}</td>
              <td>{{item.numberOfEmployees}}</td>
              <td>{{item.reference }}</td>
              <td (click)="$event.stopPropagation();" class="d-flex align-items-center">

                <app-button title="View" class="mr-1" icon="chevron_right"
                  routerLink="/processed-contribution/{{item.employerContributionHeaderId}}">
                </app-button>

                <app-button title="Copy" color="secondary" icon="content_copy"
                  routerLink="/add-contribution/copy/{{item.employerContributionHeaderId}}">
                </app-button>


                <!-- <button class="option" mat-menu-item routerLink="/view-contribution/{{item.contributionId}}">
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <button class="option" mat-menu-item routerLink="/add-contribution/copy/{{item.contributionId}}">
                  <mat-icon>content_copy</mat-icon>
                </button> -->

                <!-- <button class="option" mat-mini-fab [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/view-contribution/{{item.contributionId}}">
                    <mat-icon>chevron_right</mat-icon> View
                  </button>
                  <button mat-menu-item routerLink="/add-contribution/copy/{{item.contributionId}}">
                    <mat-icon>content_copy</mat-icon> Clone
                  </button>
                </mat-menu> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-component-wrapper>
