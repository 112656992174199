import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { mainLayoutReducer } from '../layout/main/reducer'
import { SMRTState } from './smrt.states';

export const featureKey = 'smrt';

export const reducers = new InjectionToken<ActionReducerMap<SMRTState>>(featureKey, {
  factory: () => ({
    mainLayout: mainLayoutReducer
  })
});
