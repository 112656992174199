import * as Actions from './common.actions';
import { ICommonState } from './common.state';
import { Action, createReducer, on } from '@ngrx/store';
import { LoggedInModel } from 'src/app/model/logged-in.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';

export const commonState: ICommonState = {
  isLoading: false,
  screenWidth: window.innerWidth,
  systemConfig: {} as SystemConfigModel,
  loggedInModel: {} as LoggedInModel
};

const reducerList = createReducer(commonState,

  on(Actions.IsLoadingAction, (state, { payload }) => {
    if (state.isLoading == payload)
      return state;
    return {
      ...state,
      isLoading: payload,
    };
  }),

  on(Actions.ChangeScreenWidthAction, (state, { payload }) => {
    return {
      ...state,
      screenWidth: payload,
    };
  }),

  on(Actions.SystemConfigurationResponseAction, (state, { payload }) => {
    return {
      ...state,
      systemConfig: payload,
    };
  }),
  on(Actions.IsLoggedInResponseAction, (state, { data }) => {
    return {
      ...state,
      loggedInModel: data
    };
  }),

);

export function commonReducer(state: any | undefined, action: Action) {
  return reducerList(state, action);
}
