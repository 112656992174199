<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options backlink="">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Processed Contribution</div>
      </div>

      <div class="d-flex flex-column" slot="end">
        <app-button class=" float-right" title="Copy" icon="content_copy" (onClick)="onCloneClick()">
        </app-button>
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-processed-contribution></app-processed-contribution>
      </div>
    </app-component-wrapper>

  </div>
</div>
