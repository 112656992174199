import { Directive, ElementRef, Renderer2, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[infinitescrollcomp]',
})
export class InfiniteScrollCompDirective {
  isBlocked = false;
  @Output('event') event: EventEmitter<any> = new EventEmitter();
  @HostListener("scroll", ["$event"])
  onWindowScroll($event) {
    var comp = $event.target;
    //In chrome and some browser scroll is given to body tag
    let pos = (comp.scrollTop || document.body.scrollTop) + comp.offsetHeight;
    let max = comp.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (Math.ceil(pos) >= Math.ceil(max)*0.90 && !this.isBlocked) {
      this.isBlocked = true;
      this.event.emit();
      setTimeout(() => {
        this.isBlocked = false;
      }, 500);
    }
  }

  constructor(public renderer: Renderer2, public hostElement: ElementRef) {
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {
  }

}
