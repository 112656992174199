import { KeyValueModel } from './../../../../model/keyValueModel';
import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { EmployeeModel, NewEmployeeModel } from 'src/app/model/employee.model';

export const ResetFormAction = createAction('[Add Employee View] reset form');

export const RequestAction = createAction('[Add Employee View] request data by Id', props<{ id: number }>());

export const ResponseAction = createAction('[Add Employee View] response data by Id',
  props<{ payload: NewEmployeeModel }>());

export const SubmitRequestAction = createAction('[Add Employee View] Add Employee details',
  props<{ payload: NewEmployeeModel }>());

export const SubmitResponseAction = createAction('[Add Employee View]  Employee update completed',
  props<{ payload: OkModel<string> }>());

  export const RequestContributionRateAction = createAction('[Add Employee View] request ContributionRate');

export const ResponseContributionRateAction = createAction('[Add Employee View] response ContributionRate ',
  props<{ payload: KeyValueModel[] }>());
