<app-component-wrapper class="w-100 mr-3 flex-fill" compclass="flex-fill ">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="employee-view-overview-container" class="card" infinitescrollcomp (event)="endOfPageEvent()">
      <app-no-items message="No employee found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
      <div class="table-wrapper">
        <div class="alert alert-info" *ngIf="'employerPortal_Employee_Details'|message|async as tooltip" [innerHTML]="tooltip">
        </div>
        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="filter$ | async; let form">
          <app-entry title="Search" [control]="form.controls.q" icon="search" class="w-50 pr-1">
          </app-entry>
        </form>
          <table class="table table-hover" *ngIf="(list$ |async)?.length > 0">
            <thead>
              <tr>
                <th scope="col">Employee Code</th>
                <th scope="col">Reference Number</th>
                <th scope="col">Full Name</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Status</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Member Type</th>
                <th scope="col" style="width: 80px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of (list$ | async)" class="click"
                routerLink="/employee-details/{{item.employeeId}}/{{item.employment.id}}">
                <td>{{item.employeeNumber}}</td>
                <td>{{item.referenceNumber}}</td>
                <td>{{item.fullName}}</td>
                <td>{{item.dateOfBirth | date:'dd/MM/yyyy'}}</td>
                <td>
                  <span *ngIf="item.status == 'Active'" class="badge badge-light-success">{{item.status }}</span>
                  <span *ngIf="item.status == 'Inactive'" class="badge badge-light-danger">{{item.status }}</span>
                </td>
                <td>{{item.employment.startDate | date:'dd/MM/yyyy'}}</td>
                <td>{{item.employment.endDate | date:'dd/MM/yyyy'}}</td>
                <td>{{item.memberType }}</td>
                <td>
                  <app-button title="View" icon="chevron_right" >
                  </app-button>
                </td>
              </tr>
            </tbody>
          </table>

        <div class="loading-page d-flex flex-fill align-items-center" *ngIf="isLoading$ | async">
          <mat-spinner style="margin: auto;" [diameter]="40"></mat-spinner>
        </div>


      </div>
    </div>
  </div>
</app-component-wrapper>
