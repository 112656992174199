import { OkModel } from '../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ContributionModel } from 'src/app/model/contribution.model';
import { ContributionResponseModel } from 'src/app/model/contribution-response-model';

export const ResetFormAction = createAction('[Edit Contribution View] reset form');

export const RequestAction = createAction('[Edit Contribution View] request data by Id', props<{ id: number }>());

export const ResponseAction = createAction('[Edit Contribution View] response data by Id',
  props<{ payload: ContributionModel }>());

export const SaveRequestAction = createAction('[Edit Contribution View] save Contribution details',
  props<{ payload: ContributionModel }>());

export const SaveResponseAction = createAction('[Edit Contribution View] Contribution save completed',
  props<{ payload: OkModel<number> }>());

export const SubmitRequestAction = createAction('[Edit Contribution View] submit Contribution details',
  props<{ payload: ContributionModel }>());


export const LoadUploadFileAction = createAction('[Edit Contribution View] upload file response',
  props<{ payload: ContributionModel }>());

export const SubmitResponseAction = createAction('[Edit Contribution View]  Contribution update completed',
  props<{ payload: OkModel<ContributionResponseModel> }>());

export const SumTotalsAction = createAction('[Edit Contribution View] Contribution totals updated');

export const RequestCloneAction = createAction('[Edit Contribution View] request clone data by Id', props<{ id: number }>());

export const ResponseCloneAction = createAction('[Edit Contribution View] response clone data by Id',
  props<{ payload: ContributionModel }>());

export const ColumnSortAction = createAction("[Edit Contribution View] sort records by column", props<{ column: string, direction: string }>());
