import { AddressModel } from '@ifaa-components/ui-components';
import { EmployerContactModel } from './employer-contact.model';
export class EmployerModel {
    employerId: number = 0;
    name: string = '';
    employerNumber: string = '';
    email: string = '';
    abn: string = '';
    primaryContact: EmployerContactModel = new EmployerContactModel();
    postalAddress: AddressModel = new AddressModel();
    businessAddress: AddressModel = new AddressModel();
    defaultJetcoAgreement: number = 0;
    defaultCharityDonation: number = 0;
    isPostalSameAsBusiness: boolean = false;
}
