<div id="certificate-of-employment-edit-container">
  <div class="card">
    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="d-flex flex-wrap">

        <app-readonly title="Employee Number" [value]="form.value.employeeCode" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Employee Name" [value]="form.value.employeeFullName" class="w-50 pl-1">
        </app-readonly>

        <app-readonly title="Date of Birth" [value]="form.value.dateOfBirth | date:'dd/MM/yyyy' "
          class="w-50 pr-1 ">
        </app-readonly>

        <h3 class="w-100 mt-3">Employment Detail</h3>
        <app-readonly title="Employer Name" [value]="form.value.employerName" icon="contact_page" class="w-100 pr-1">
        </app-readonly>

        <app-readonly title="Start Date" [value]="form.value.employmentStartDate  | date:'dd/MM/yyyy'" class="w-50 pr-1">
        </app-readonly>

        <app-entry-date title="End Date" [date]="form.controls.employmentEndDate" icon="contact_page"
          class="w-50 pr-1">
        </app-entry-date>
        <div class="alert alert-info">
          <p>I certify that:</p>
          <p>The Employer no longer requires the work which the Employee has been performing to be done by anyone and
            this has led to the termination AND/OR the Employee's employment was terminated due to lack or work; AND the
            termination was not by the Employee by voluntary resignation, retirement, death or permanent disability or
            abandonment, or by the Employer for disciplinary reasons</p>
          <app-button-toggle title="" class="w-100 pr-1 mb-3" list="yesNo"
            [control]="form.controls.agreeToCertify"></app-button-toggle>
        </div>
        <app-entry *ngIf="!form.value.agreeToCertify" title="If No, please provide details"
          [control]="form.controls.certifyNoDetails" icon="contact_page" class="w-100 pr-1">
        </app-entry>
        <div *ngIf="form.value.agreeToCertify">
          <app-entry-number title="Employee's Annual Salary" [control]="form.controls.employeeAnnualSalary" [decimalplaces]="2"
            icon="attach_money" class="w-50 pl-1">
          </app-entry-number>

          <app-entry-number title="Unpaid absence in weeks" [control]="form.controls.unpaidAbsenceInWeeks" icon="tag" [decimalplaces]="0"
            class="w-50 pl-1">
          </app-entry-number>

          <app-entry-number title="Number of weeks for redundancy calculation" [decimalplaces]="0"
            [control]="form.controls.numberOfWeeksRedundancyCalculation" icon="tag" class="w-50 pl-1">
          </app-entry-number>

          <app-entry-number title="Total redundancy payment due to employee # times weekly salary rate" [decimalplaces]="2"
            [control]="form.controls.totalRedundancyPayment" icon="attach_money" class="w-50 pl-1">
          </app-entry-number>

          <app-entry-number title="Number of weeks payment into CIRT on behalf of employee" [decimalplaces]="0"
            [control]="form.controls.numberOfWeeksPayment" icon="tag" class="w-50 pl-1">
          </app-entry-number>
   


          <app-entry-number title="Total amount paid into CIRT" [control]="form.controls.totalAmountDue" [decimalplaces]="2"
            icon="attach_money" class="w-50 pl-1">
          </app-entry-number>

          <app-entry-number title="Total payment by employer" [control]="form.controls.totalPaymentByEmployer" [decimalplaces]="2"
            icon="attach_money" class="w-50 pl-1">
          </app-entry-number>

            <h3 class="w-100 mt-3">TCR Scale</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Weeks</th>
                  <th scope="col"></th>
                  <th scope="col">Weeks</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>Less than 1 year old</td>
                  <td>nil</td>
                  <td>More than 6 years but not more than 7 years</td>
                  <td>11</td>
                </tr>

                <tr>
                  <td>1 year but not more than 2 years</td>
                  <td>4</td>
                  <td>More than 7 years but not more than 8 years</td>
                  <td>13</td>
                </tr>

                <tr>
                  <td>More than 2 years but not more than 3 years</td>
                  <td>6</td>
                  <td>More than 8 years but not more than 9 years</td>
                  <td>14</td>
                </tr>

                <tr>
                  <td>More than 3 years but not more than 4 years</td>
                  <td>7</td>
                  <td>More than 9 years but not more than 10 years</td>
                  <td>16</td>
                </tr>

                <tr>
                  <td>More than 4 years but not more than 5 years</td>
                  <td>8</td>
                  <td>More than 10 years</td>
                  <td>12</td>
                </tr>

                <tr>
                  <td>More than 5 years but not more than 6 years</td>
                  <td>10</td>
                  <td></td>
                  <td></td>
                </tr>

              </tbody>
            </table>
        </div>

      </div>
      <div class="components-actions mt-4">
        <app-button loading class="float-right w-25" title="Save Details" icon="save" type="submit"
          [isDisabled]="form.isInvalid" (onClick)="onSubmitClick(form.value)">
        </app-button>
      </div>

    </form>
  </div>

  <ng-template #myTemplate>
    <div class="p-4">
      <h3 class="mb-3">Thank you for submitting a Certificate of Employment for {{employeeFullName}}
      </h3>
      <p>
        You may still add contributions to this employee if required , they will now appear in your Inactive Employee Listing
      </p>

      <app-button loading class="float-right  mb-4 mt-4" title="Return to Employee Details" icon="keyboard_return"
        type="submit" (onClick)="onGoToDetailsClick()">
      </app-button>
    </div>
  </ng-template>
</div>
