<div id="mapfile-edit-container">
  <div class="card">
    <div class="d-flex flex-column" slot="start">
      <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
        <mat-horizontal-stepper [linear]="true" #stepper [selectedIndex]="(currentStep$ | async)">
          <mat-step *ngIf="form$ | async; let form" title="test">
            <div class="d-flex flex-row pt-2">
              <div class="d-flex align-items-center mr-3" style="flex-direction: column-reverse;">
                <app-entry-file [file]="form.controls.file" class="d-flex flex-fill " title="Attachment"
                  [extension]="extensions" (onGetFileContentChange)="onFileContentChange($event)"
                  [maxSize]="maxfilesize">
                </app-entry-file>
              </div>

              <div class="alert alert-info d-flex  description align-items-center flex-fill mb-0">
                <div class="d-flex flex-column">
                  <div class="desc-title">Select the CSV file to start mapping</div>
                  <div class="">
                    <div class="d-flex align-items-center mb-2">

                      <mat-icon class="mr-2">support</mat-icon>
                      Need help with a file?
                    </div>
                    <ul>
                      <li>
                        <a style="margin-right: 4px; margin-left: 0px;"
                          [href]="(tenantConfig$|async)?.employerPortalContributionUploadTemplate">
                          Download a blank CSV
                          template here
                        </a> - use this to create a contribution file using your own data.
                      </li>
                      <li>
                        <a class="link-primary" (click)="onGetTemplateClick()">
                          Download a Employee Populated CSV file
                          here
                        </a>
                        - simply add your weeks and contribution amounts.
                      </li>
                    </ul>

                  </div>
                </div>
              </div>

            </div>

          </mat-step>
          <mat-step *ngIf="form$ | async; let form">
            <!-- <h3>Map the file</h3> -->

            <div class="main-content ">
              <div class="title">Mapping File - Excel
              </div>
              <div class="menu-bar pb-2">
                <div class="text-disabled"><span>File</span></div>
                <div joyrideStep="firstStep" title="CSV Content" text="Use this view to see what is in your csv">
                  <span>Mapping CSV</span>
                </div>
                <div class="text-disabled"><span>Insert</span></div>
                <div class="text-disabled"><span>Page Layout</span></div>
                <div class="text-disabled"><span>Formulas</span></div>
                <div class="text-disabled"><span>Data</span></div>
                <div class="text-disabled"><span>Review</span></div>
                <div class="text-disabled"><span>View</span></div>
                <div class="" (click)="onHelpClick()">
                  <span>
                    How to use this view
                  </span>
                </div>
              </div>


              <div class="cells cells-{{(totalColumn$ | async)}}">
                <div class="cells__spacer"></div>
                <div class="cells__alphabet " [class.selected]="mapToSelectIndex == i?'selected':''" id="alphabet-{{i}}"
                  (click)="mapTo(i,form.controls.mapping.controls[i].controls)"
                  *ngFor="let item of (columns$ | async); let i = index">{{item}}
                </div>
                <div class="cells__number">1</div>
                <div class="cells__number">2</div>
                <div class="cells__number">3</div>
                <div class="cells__number">4</div>
                <div class="cells__number">5</div>
                <div class="cells__number" joyrideStep="secondStep" title="Map CSV Column"
                  text="Select the column to map">6</div>


                <div *ngFor="let item of form.controls.mapping.controls;let i = index"
                  (click)="mapTo(i,form.controls.mapping.controls[i].controls)"
                  class="cells__input {{item.value.columnName != mapToSelected?.columnName.value?'unselected':'selected'}}">
                  {{item.value.columnName}}
                </div>
                <div *ngFor="let item of getFileData(form.value.mapping);let i = index;trackBy: trackByFn"
                  (click)="mapToContent(item)"
                  class="cells__input {{item.key != mapToSelected?.columnName.value?'unselected':'selected'}}">
                  <div>
                    {{item.value}}
                  </div>
                </div>

                <div *ngFor="let item of form.controls.mapping.controls;let i = index;"
                  class="cells__input mapto {{item.value.columnName != mapToSelected?.columnName.value?'unselected':'selected'}}"
                  (click)="mapTo(i,item.controls)">
                  <!-- (click)="mapTo(item.controls)" -->
                  <div *ngIf="item.value.mapTo && item.value.columnName" appanimatecss="animate__flash"
                    class="mapped d-flex flex-row align-items-center">
                    <div class="d-flex flex-fill text-bold">{{item.value.mapTo}}</div>
                    <mat-icon>done</mat-icon>
                  </div>
                  <!-- [matMenuTriggerFor]="menu" -->
                  <div *ngIf="!item.value.mapTo && item.value.columnName" class="d-flex flex-row align-items-center">
                    <a class="d-flex flex-fill text-bold">Map to...</a>
                    <mat-icon *ngIf="item.value.columnName == mapToSelected?.columnName.value">keyboard_arrow_down
                    </mat-icon>
                    <mat-icon *ngIf="item.value.columnName != mapToSelected?.columnName.value">chevron_left</mat-icon>
                  </div>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngFor="let i of mapToOptions$ | async">{{i.value}}</button>
                  </mat-menu>
                </div>


                <input [style]="form.value.mapping.length == 0? 'display: block': 'display: none'"
                  *ngFor="let item of [].constructor(90); let i = index" class="cells__input" />

              </div>

            </div>
            <div *ngIf="mapToSelected" class="cell-content animate  {{mapToAnimation}}"
              appanimatecss="animate__fadeInDown">
              <div class="d-flex flex-row">
                <div class="flex-fill" joyrideStep="fourthStep" title="Mapping"
                  text="Use this section to map the columns from you CSV with the available options">
                  <mat-icon class="table-chart-icon">table_chart</mat-icon>
                  Mapping column {{selectedColumn}} to
                  <mat-icon class="mr-2 ml-2" style="transform: translateY(10px) rotate(-90deg);">
                    subdirectory_arrow_left</mat-icon>
                </div>
                <div joyrideStep="thirdStep" title="Navigation" text="Navigate back and forth">
                  <a class=" mr-2 d-flex align-items-center no-decoration nav-col-link"
                    (click)="onPreviousColumnClick(form.controls.mapping)">
                    <mat-icon class="nav-link-icon mr-2">west</mat-icon>
                    Previous column
                  </a>
                  <div class="ml-1 mr-1">|</div>
                  <a class=" mr-3 ml-2 d-flex align-items-center no-decoration nav-col-link"
                    (click)="onNextColumnClick(form.controls.mapping)">Next column
                    <mat-icon class="ml-2 nav-link-icon">east</mat-icon>
                  </a>
                </div>

              </div>
              <div class="mapToSelected d-flex flex-column align-items-center m-auto">
                <!-- Map To
                <small>{{mapToSelected.columnName.value}}</small> -->
              </div>
              <div class="d-flex flex-column">
                <app-button-toggle title="" class="w-100 pr-1 mb-3 options" [customList]="mapToOptionsKV$"
                  [requiredkeys]="requiredColumnKeys" [control]="mapToSelected.mapTo"></app-button-toggle>



              </div>
            </div>
          </mat-step>
          <mat-step *ngIf="form$ | async; let form">
            <div class="alert alert-light">
              <div
                *ngIf="(contributionCheck$ | async)?.errorList.length>0 && !reviewScreenHasError((contributionCheck$ | async)?.errorList)">
                <h3>Validation Screen - Warning Only</h3>
                <p>
                  The below warnings have been identified when validating your file. Please confirm you are ok to
                  proceed by selecting ok for each item.
                </p>
                <p>
                  Any rows not confirmed will be removed from the file for processing.
                </p>
              </div>

              <div
                *ngIf="(contributionCheck$ | async)?.errorList.length>0 && reviewScreenHasError((contributionCheck$ | async)?.errorList)">
                <h3>Validation Screen - Warning and Errors</h3>
                <p>
                  The below Errors have been identified when validating your file. Please review and update your file
                  accordingly.
                </p>

              </div>
              <div *ngIf="(contributionCheck$ | async)?.errorList?.length == 0">
                <div class="d-flex flex-row align-items-center">
                  <div>
                    <mat-icon class="done">task_alt</mat-icon>
                  </div>
                  <div>
                    <h3>Validation Screen - No Warnings or Errors</h3>
                    <p>
                      No Warnings or Errors have been identified in your data file, please proceed to the contribution
                      screen
                    </p>
                  </div>
                </div>


              </div>
            </div>

            <div class="d-flex flex-row mb-3">
              <div class="alert alert-warning flex-fill mr-2 p-4 d-flex flex-row align-items-center">
                <mat-icon class="custom-icon" style="color: #ffbf00;">warning</mat-icon>
                <div class="d-flex flex-row flex-fill">
                  <h2 class="flex-fill">Warnings</h2>
                  <div class="number">{{warningCount((contributionCheck$ | async)?.errorList)}}</div>
                </div>
              </div>
              <div class="alert alert-danger flex-fill ml-2 p-4 d-flex flex-row align-items-center">
                <mat-icon class="custom-icon" style="color: tomato">error</mat-icon>
                <div class="d-flex flex-row flex-fill">
                  <h2 class="flex-fill">Errors</h2>
                  <div class="number">{{errorCount((contributionCheck$ | async)?.errorList)}}</div>
                </div>
              </div>
            </div>
            <table class="table table-hover {{(isEditMode$|async)== true?'edit':''}}"
              *ngIf="(contributionCheck$ | async)?.errorList">
              <thead>
                <tr>
                  <th></th>
                  <th>Line</th>
                  <th>Column</th>
                  <th>Value</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of (contributionCheck$ | async)?.errorList;let i = index;">
                  <td>
                    <mat-icon style="color: #ffbf00;" *ngIf="!item.isError">warning</mat-icon>
                    <mat-icon style="color: tomato" *ngIf="item.isError">error</mat-icon>
                  </td>
                  <td>{{item.line}}</td>
                  <td>{{item.column}}</td>
                  <td>
                    <span *ngIf="item.value">{{item.value}}</span>
                    <span *ngIf="item.name">{{item.name}}</span>
                  </td>
                  <td>{{item.message}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="alert alert-info" *ngIf="(contributionCheck$ | async)?.errorList.length >0">
              <div *ngFor="let item of (contributionCheck$ | async)?.errorList">
                {{item}}
              </div>
            </div> -->
          </mat-step>

          <mat-step *ngIf="form$ | async; let form">
            <app-contribution-edit class="map-file-contribution" [isUploadFile]="true"
              [setContributionData]="contribution$|async"></app-contribution-edit>
          </mat-step>
        </mat-horizontal-stepper>
      </form>
    </div>
    <div class="d-flex flex-column" slot="start">
      <div class="d-flex flex-row flex-row-reverse components-actions m-4 pt-0" slot="actions"
        *ngIf="form$ | async; let form">
        <app-button class=" " title="Next"
          *ngIf="(currentStep$ | async) < (totalSteps$ | async) && ((currentStep$ | async) == 0)" [form]="form"
          [isDisabled]="form.isInvalid" icon="east" (onClick)="onNextClick()">
        </app-button>

        <app-button class=" " title="Validate"
          *ngIf="(currentStep$ | async) < (totalSteps$ | async) && ((currentStep$ | async) == 1)" [form]="form"
          [isDisabled]="requiredColumnKeys.length > 0" icon="east"
          (onClick)="onValidateRequestClick(form.value.mapping)">
        </app-button>

        <app-button class=" "
          title="{{(contributionCheck$ | async)?.errorList.length>0?'Accept Warnings and Submit':'Proceed'}}"
          *ngIf="(currentStep$ | async) < (totalSteps$ | async) && ((currentStep$ | async) == 2) && !reviewScreenHasError((contributionCheck$ | async)?.errorList)"
          [form]="form" [isDisabled]="requiredColumnKeys.length > 0" icon="east"
          (onClick)="onSubmitRequestClick(form.value.mapping)">
        </app-button>




        <!-- <app-button class=" " title="Submit" *ngIf="(currentStep$ | async) == (totalSteps$ | async)"
          [isDisabled]="form.isInvalid" icon="send" (onClick)="onSubmitClick(form.value)">
        </app-button> -->

        <app-button class=" invert-btn mr-3" title="Back" icon="west"
          *ngIf="(currentStep$ | async) > 0 && (currentStep$ | async) <= (totalSteps$ | async)"
          (onClick)="onPreviousClick(stepper)">
        </app-button>

      </div>
    </div>
  </div>
</div>
