import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { EPEmployerService } from 'src/app/services/EmployerPortalServices/ep-employer.service';
import * as ActionsList from './actions';

@Injectable()
export class MainLayoutEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: EPEmployerService
    //private service: MembersService
    ) {
  }

  // searchRequest$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.MainSearchRequestAction),
  //   switchMap((action: any) => this.service.get(action.search, action.page)),
  //   map((data: any) => {
  //     return ActionsList.MainSearchResponseAction({ payload: data.members });
  //   })
  // ));


  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestEmployerInfoAction),
    switchMap((action: any) => this.service.getEmployer()),
    map((data: any) => {
      return ActionsList.ResponseEmployerInfoAction({ payload: data.data });
    })
  ));

}
