<app-component-wrapper class="w-100 mr-3 flex-fill" compclass="flex-fill " infinitescroll
  (event)="endOfPageFullEvent()">

  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="new-employee-view-overview-container" class="card" endofpage (event)="endOfPageEvent()"
      [class.compact]="fullpage == false">
      <app-no-items message="No new employees found" *ngIf="(list$ |async)?.length === 0"></app-no-items>
      <div class="table-wrapper">
        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="filter$ | async; let form">
          <app-entry title="Search" [control]="form.controls.q" icon="search" class="w-50 pr-1">
          </app-entry>
        </form>
        <cdk-virtual-scroll-viewport itemSize="50">
          <table class="table table-hover" *ngIf="(list$ |async)?.length > 0">
            <thead>
              <tr>
                <th scope="col">Reference Number</th>
                <th scope="col">Full Name</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Start Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *cdkVirtualFor="let item of list$ | async" class="click" routerLink="/new-employee-details/{{item.employeeId}}">
                <td>{{item.referenceNumber}}</td>
                <td>{{item.fullName}}</td>
                <td>{{item.dateOfBirth | date:'dd/MM/yyyy'}}</td>
                <td>{{item.employment.startDate | date:'dd/MM/yyyy'}}</td>
              </tr>
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</app-component-wrapper>