import { EPEmployerService } from '../../../../services/EmployerPortalServices/ep-employer.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ProcessedContributionsViewEffects {
  constructor(private actions$: Actions,
    private service: EPEmployerService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getProcessedContributions(action.page)),
    map((data: any) => {
      return ActionsList.ResponseAction({ payload: data.data });
    })
  ));
}
