<div id="employee-edit-container">
  <div class="card">
    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="d-flex flex-wrap">
        <app-readonly title="Employee Name" [value]="form.value.fullName" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Employee Number" [value]="form.value.employeeNumber" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Status" [value]="form.value.status" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Date of Birth" [value]="form.value.dateOfBirth  | date:'dd/MM/yyyy'" class="w-50 pr-1">
        </app-readonly>

        <app-entry title="Email" [control]="form.controls.email" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Home Phone" [control]="form.controls.homePhone" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Mobile" [control]="form.controls.mobile" class="w-50 pr-1">
        </app-entry>

        <app-entry title="Occupation" [control]="form.controls.occupation" class="w-50 pr-1">
        </app-entry>


        <app-entry-address-accurity title="{{ 'POSTAL_ADDRESS' | translate }}" [address]="form.controls.postalAddress"
        [isSearchMode]="false" class="w-100">
      </app-entry-address-accurity>


        <entry-slide-toggle title="Residential same as postal address?" class="w-100"
          [control]="form.controls.isPostalSameAsResidential" iconTrue="" iconFalse="">
        </entry-slide-toggle>

        <app-entry-address-accurity title="{{ 'RESIDENTIAL_ADDRESS' | translate }}" [isSearchMode]="false" 
        *ngIf="!form.value.isPostalSameAsResidential"  [address]="form.controls.residentialAddress" class="w-100">
      </app-entry-address-accurity>

        <!-- <div class="w-100 mb-4 alert alert-secondary">
          <label>Submit a Certificate of Employment if the Employee has left Employment</label>
          <app-button loading class="w-25 " title="Certificate of Employment" icon="badge"
            routerLink="/certificate-of-employment-edit/{{form.value.employeeId}}">
          </app-button>
        </div> -->

        <app-readonly title="Employment Start Date" [value]="form.value.employment.startDate | date:'dd/MM/yyyy'"
          class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Employment End Date" [value]="form.value.employment.endDate  | date:'dd/MM/yyyy'"
          class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="EBA" [value]="form.value.eba" icon="contact_page" class="w-50 pr-1">
        </app-readonly>

        <app-readonly title="Contribution Balance" [value]="form.value.contributionBalance" icon="local_atm"
          class="w-50 pr-1" tooltip="Balance is for current Employer only">
        </app-readonly>

      </div>
      <div class="components-actions">
        <app-button loading class="float-right w-25" title="Save Details" icon="save" type="submit" [form]="form"
          [isDisabled]="form.isInvalid" (onClick)="onSubmitClick(form.value)">
        </app-button>
      </div>

    </form>
  </div>
</div>
