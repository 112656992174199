import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { IEmployeeEditState } from './state';
import { RequestAction, ResetFormAction, ResponseAction, SubmitResponseAction } from './actions';
import { AddressModel } from '@ifaa-components/ui-components';
import { EmployeeModel } from 'src/app/model/employee.model';
import { EmploymentModel } from 'src/app/model/employment.model';

export const formName = 'EmployeeEditForm';

export const state: IEmployeeEditState = {
  form: createFormGroupState(formName, new EmployeeModel()),
  lastModifiedTime: null
};

export const validateEditEmployeeForm = updateGroup<EmployeeModel>({
  email: validate(email)
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: setValue(new EmployeeModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue(new EmployeeModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: setValue(Object.assign(new EmployeeModel(), payload,
      {
        postalAddress: Object.assign(new AddressModel(), payload.postalAddress),
        residentialAddress: Object.assign(new AddressModel(), payload.residentialAddress),
        employment: Object.assign(new EmploymentModel(), payload.employment),
      }
      ))(state.form),
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

const editEmployeeReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditEmployeeForm,
);

export function employeeEditReducer(state: any | undefined, action: Action) {
  return editEmployeeReducerFormState(state, action);
}
