import { CirtModule } from './modules/cirt/cirt.module';
import { SMRTModule } from './modules/smrt/smrt.module';
import { environment } from './../environments/environment';
import { Type } from '@angular/core';
import { TenantDashboardComponent as CirtTenantDashboardComponent } from './modules/cirt/views/dashboard/dashboard.component'
import { MainMasterComponent as CirtMainMasterComponent } from './modules/cirt/layout/main/main.master'
import { NotLoggedInMasterComponent as CirtNotLoggedInMasterComponent } from './modules/cirt/layout/not-loggedin/not-loggedin.master';

import { TenantDashboardComponent as SMRTTenantDashboardComponent } from './modules/smrt/views/dashboard/dashboard.component'
import { MainMasterComponent as SMRTMainMasterComponent } from './modules/smrt/layout/main/main.master'
import { NotLoggedInMasterComponent as SMRTNotLoggedInMasterComponent } from './modules/smrt/layout/not-loggedin/not-loggedin.master';


export class GetTenantModule {
  static getModule(list: any[]) {
    if (environment.code == 'cirt')
      list.push(CirtModule);
    if (environment.code == 'smrt')
      list.push(SMRTModule);
  }
}


export class GetTenantNotLoggedInLayout {
  static layout(): Type<any> {
    if (environment.code == 'cirt')
      return CirtNotLoggedInMasterComponent;
    if (environment.code == 'smrt')
      return SMRTNotLoggedInMasterComponent;

    return null;
  }

}

export class GetTenantDashboard {
  static layout(): Type<any> {

    if (environment.code == 'cirt')
      return CirtTenantDashboardComponent;

    if (environment.code == 'smrt')
      return SMRTTenantDashboardComponent;

    return null;
  }

}

export class GetTenantMainLayout {
  static mainLayout(): Type<any> {
    if (environment.code == 'cirt')
      return CirtMainMasterComponent;

    if (environment.code == 'smrt')
      return SMRTMainMasterComponent;

    return null;
  }

}

export function GetMainLayout(): Type<any> {

  if (environment.code == 'cirt')
    return CirtMainMasterComponent;

  if (environment.code == 'smrt')
    return SMRTMainMasterComponent;

  return null;

}
