
import { ILoginState } from './state';
import { LoginModel } from '../../model/login.model';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { CheckAvailabilityResponseAction, LoginAction, LoginCompletedAction } from './actions';

export const formName = 'loginForm';

export const loginState: ILoginState = {
  form: createFormGroupState(formName, new LoginModel()),
  isLoading: false,
  isUnavailable: false,
  isUnavailableMessage: ''
};

export const validateLoginForm = updateGroup<LoginModel>({
  username: validate(required),
  password: validate(required),
});

const reducer = createReducer(loginState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(LoginAction, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(CheckAvailabilityResponseAction, (state, { payload }) => {
    return {
      ...state,
      isUnavailable: payload.data,
      isUnavailableMessage: payload.message
    };
  }),
  on(LoginCompletedAction, (state) => {
    return {
      ...state,
      isLoading: false
    };
  }),
);

const loginReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateLoginForm,
);

export function loginReducer(state: any | undefined, action: Action) {
  return loginReducerFormState(state, action);
}
