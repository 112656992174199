import { AppButtonToggleComponent, EntryFieldDropdownComponent, EntryFieldRadioComponent } from '@ifaa-components/ui-components';
import { Directive, ElementRef, Renderer2, Input, Host, Self, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import {    commomState_BooleanList,
  commomState_GenderList, commomState_YesNoList } from 'src/app/store/common/common.selectors';

@Directive({
  selector: '[list]',
})
export class AppRadioDirective {

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public radio: EntryFieldRadioComponent,
    @Host() @Self() @Optional() public select: EntryFieldDropdownComponent,
    @Host() @Self() @Optional() public buttonToggle: AppButtonToggleComponent,
    ) {

    // if(this.button)
    // this.button.isLoading = commomState_IsLoading;
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {

  }

  @Input() set list(value: string) {
    switch (value) {
      case 'gender':
        this.setList(this.store.pipe(select(commomState_GenderList)));
        break;
      case 'boolean':
        this.setList(this.store.pipe(select(commomState_BooleanList)));
        break;
      case 'yesNo':
        this.setList(this.store.pipe(select(commomState_YesNoList)));
        break;
    }
  }

  setList(list: any) {
    if (this.radio) {
      this.radio.customList = list;
    }
    if (this.select) {
      this.select.customList = list;
    }
    if (this.buttonToggle) {
      this.buttonToggle.customList = list;
    }
  }
}
