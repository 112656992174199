<div id="member-view-container">
  <div class="card">
    <div *ngIf="data$ | async; let data" class="d-flex flex-wrap">
      <app-readonly title="Employer Name" [value]="data.name" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Employer Number" [value]="data.employerNumber" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>
      <app-readonly title="Employer ABN" [value]="data.abn"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Primary Contact" [value]="data.primaryContact.firstName + ' ' + data.primaryContact.lastName" class="{{itemclass}} p-1 flex-fill">
      </app-readonly>
      <app-readonly title="Email" [value]="data.primaryContact.email" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Mobile" [value]="data.primaryContact.mobile" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Position" [value]="data.primaryContact.position" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Default Jetco Agreement" [value]="data.defaultJetcoAgreement" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      <app-readonly title="Default Charity Donation" [value]="data.defaultCharityDonation" class="{{itemclass}} p-1 flex-fill"></app-readonly>
      
      <app-readonly class="w-100" title="{{ 'POSTAL_ADDRESS' | translate }}" [value]="data.postalAddress.fullAddress"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

      <app-readonly class="w-100" title="{{ 'BUSINESS_ADDRESS' | translate }}" [value]="data.businessAddress.fullAddress"
        class="{{itemclass}} p-1 flex-fill"></app-readonly>

    </div>
  </div>
</div>
