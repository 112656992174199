import { EmployerPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IProcessedContributionState } from './state';

export const state = createFeatureSelector<EmployerPortalSharedState>(featureKey);

export const processedContribution = createSelector(state, (state) => state.processedContributionsState);

export const processedContribution_Data = createSelector(
  processedContribution,
  (state: IProcessedContributionState) => state.data
);

