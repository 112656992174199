import { AppState } from 'src/app/store/app.states';
import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { commomState_SystemConfig_FeatureToggle } from 'src/app/store/common/common.selectors';

@Directive({
  selector: '[featuretoggle]',
})
export class FeatureToggleDirective {

   featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));

  @Input() featuretoggle: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef) {
  }

  ngOnChanges() {

  }

  ngAfterViewInit(): void {
    this.featuretoggle$.subscribe(x => {
      if (!x) return;

      if (x.hasOwnProperty(this.featuretoggle) && x[this.featuretoggle]) {
        return;
      }

      let el: HTMLElement = this.hostElement.nativeElement;
      if (el && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    });
  }


}
