import { Component, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../../../store/app.states';
import { EmployerPortalSharedState } from '../../store/shared.states';

@Component({
  selector: 'app-component-wrapper',
  templateUrl: './app-component-wrapper.component.html',
  styleUrls: ['./app-component-wrapper.component.scss'],
  providers: []
})
export class AppComponentWrapperComponent implements AfterViewInit {

  @Input() headerclass: string = '';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() help: string = '';
  @Input() compclass: string = '';
  @Input() fragmentid: string = '';
  currentFragment: string = null;
  isOpen = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  clickSubscription?: Subscription;
  showLoadingIcon = false;
  constructor(public store: Store<EmployerPortalSharedState>,
    private route: ActivatedRoute) {

    this.route.fragment.subscribe(f => {
      this.currentFragment = f;

      setTimeout(() => {
        this.currentFragment = null;
      }, 2000);
    })
  }

  ngAfterViewInit(): void {

  }

}
