import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms,
} from 'ngrx-forms';
import { ICertificateOfEmploymentViewState } from './state';
import { RequestAction, ResetFormAction, ResponseAction, ResponseEmployeeAction } from './actions';
import { CertificateOfEmploymentModel } from 'src/app/model/certificate-of-employment.model';
import { Helper } from '@ifaa-components/ui-components';
import { EmployeeModel } from 'src/app/model/employee.model';

var helper = new Helper();

export const state: ICertificateOfEmploymentViewState = {
  data: new CertificateOfEmploymentModel(),
  employee: new EmployeeModel()
};

const reducer = createReducer(state,
  onNgrxForms(),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      data: new CertificateOfEmploymentModel(),
    };
  }),

  on(RequestAction, (state) => {
    return {
      ...state,
      data: new CertificateOfEmploymentModel(),

    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      data: Object.assign(new CertificateOfEmploymentModel(), payload),
    };
  }),

  on(ResponseEmployeeAction, (state, { payload }) => {
    return {
      ...state,
      employee: payload
    };
  }),
);

export function certificateOfEmploymentViewReducer(state: any | undefined, action: Action) {

  return reducer(state, action);

}


