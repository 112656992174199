<div id="certificate-of-employment-container">
  <div class="page-header">
    <app-toolbar-options icon="paid">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Edit Certificate of Employment</div>
      </div>

    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper class="mt w-100" icon="">
      <div class="d-flex flex-column" slot="start">
        <app-certificate-of-employment-edit></app-certificate-of-employment-edit>
      </div>
    </app-component-wrapper>

  </div>
</div>
