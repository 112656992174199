import { addEmployee_contributionRate, addEmployee_Form, addEmployee_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, RequestContributionRateAction, ResetFormAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeModel, NewEmployeeModel } from 'src/app/model/employee.model';
import { SetValueAction } from 'ngrx-forms';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss']
})
export class EmployeeAddComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(addEmployee_Form));
  lastModified$ = this.store.pipe(select(addEmployee_LastModified));
  contributionRate$ = this.store.pipe(select(addEmployee_contributionRate));

  constructor(public store: Store<EmployerPortalSharedState>,
    public activatedRoute: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.dispatch(this.store, RequestContributionRateAction());

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['employee-details']);
        this.dispatch(this.store, ResetFormAction());
      }
    });

    this.sub = this.form$.subscribe(form => {
      if(form)
      {
        if (!form.value.postalAddress.isRequired)
          this.store.dispatch(new SetValueAction(form.controls.postalAddress.controls.isRequired.id, true));
      }       
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: NewEmployeeModel) {
    this.dispatch(this.store, SubmitRequestAction({ payload: data }));

  }

}
