import { viewCertificateOfEmployment_Employee, viewCertificateOfEmployment_Data } from './selectors';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, RequestEmployeeAction, ResetFormAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { EmployerPortalSharedState } from '../../store/shared.states';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { commomState_YesNoList } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-certificate-of-employment-view',
  templateUrl: './certificate-of-employment-view.component.html',
  styleUrls: ['./certificate-of-employment-view.component.scss']
})
export class CertificateOfEmploymentViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(viewCertificateOfEmployment_Data));
  yesNo$ = this.appStore.pipe(select(commomState_YesNoList));
  employee$ = this.appStore.pipe(select(viewCertificateOfEmployment_Employee));

  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  constructor(
    public store: Store<EmployerPortalSharedState>,
    public appStore: Store<AppState>,
    public activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.getRouteParam(this.activatedRoute, 'certificateid', (id) => {
      this.dispatch(this.store, RequestAction({ id: id }));
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onGoToDetailsClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/employee-details']);
  }

}
